/***************
  Fonts
***************/

$font-path: "/assets/fonts";

/* #### Generated By: http://www.cufonfonts.com #### */

/*font-face*/
@font-face {
  font-family: 'Archivo Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Archivo Regular'), url('#{$font-path}/Archivo-Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Archivo Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Archivo Italic'), url('#{$font-path}/Archivo-Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Archivo Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Archivo Medium'), url('#{$font-path}/Archivo-Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Archivo Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Archivo Medium Italic'), url('#{$font-path}/Archivo-MediumItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Archivo SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Archivo SemiBold'), url('#{$font-path}/Archivo-SemiBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Archivo SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Archivo SemiBold Italic'), url('#{$font-path}/Archivo-SemiBoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Archivo Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Archivo Bold'), url('#{$font-path}/Archivo-Bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Archivo Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Archivo Bold Italic'), url('#{$font-path}/Archivo-BoldItalic.woff') format('woff');
  }


/* Material Icons font*/

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/MaterialIcons-Regular.eot') format('eot');
  /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('#{$font-path}/MaterialIcons-Regular.woff2') format('woff2'),
  url('#{$font-path}/MaterialIcons-Regular.woff') format('woff'),
  url('#{$font-path}/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons', Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* fallback */
@font-face {
  font-family: 'Material Icons Outlined', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/fallback-matrial-font-outline.woff2') format('woff2');
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined', Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

