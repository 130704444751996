.rbc-calendar {
    width: 550px;
    height: 470px !important;
    padding: 23px 36px 35px 35px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    font-family: 'Archivo', sans-serif;
    @media(max-width:767px){
        padding: 23px 15px 35px 15px;
    }
    .rbc-toolbar {
        span:first-child {
            &.rbc-btn-group {
                order: 2;
                display: flex;
                button:nth-child(1) {
                    order: 3;
                    height: 30px;
                    line-height: 30px;
                    min-width: 80px;
                    border-radius: 5px;
                    border: solid 1px rgba(219, 219, 219, 0.8);
                    background: #fff;
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: #777777;
                    padding: 0 8px;
                    cursor: pointer;
                    margin-left: 10px;
                }
                button:nth-child(2) {
                    text-indent: -99999px;
                    width: 25px;
                    height: 30px;
                    background: url('./../images/arrow_back.svg') no-repeat center #fff;
                    background-size: 17px;
                    border: 0;
                    opacity: 0.5;
                    cursor: pointer;
                    &:focus, &:active {
                        box-shadow: none;
                    }
                  }
                  button:nth-child(3) {
                    text-indent: -99999px;
                    width: 25px;
                    height: 30px;
                    background: url('./../images/arrow_forward.svg') no-repeat center #fff;
                    background-size: 17px;
                    border: 0;
                    opacity: 0.5;
                    cursor: pointer;
                    &:focus, &:active {
                        box-shadow: none;
                    }
                  }
            }
        }
        .rbc-btn-group {
            order: 3;
            display: none;
        }
        .rbc-toolbar-label {
            order: 1;
            padding: 0;
            text-align: left;
        }
    }
}
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #f7e7d9 !important;
  }
  .rbc-month-view {
    border: 0 !important;
  }
  .rbc-month-row:last-child{
    border-bottom: 1px solid #DDD;
  }
  .rbc-day-bg.rbc-today {
  background: none;
  position: relative;
  }
  .rbc-day-bg.rbc-today:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fb7304;
    @media(max-width:767px){
        top: 0;
        left: 0;
        margin-left: 0;
        width: 100%;
        height: 58px;
        border-radius: 0;
    }
  }
  .rbc-day-bg.date-in-past {
    background: #f9f9f9;
    pointer-events: none;
  }
.rbc-month-row {
    border-top: 1px solid #DDD;
    border-left: 1px solid #DDD;
    border-right: 1px solid #DDD;
    .rbc-row-content  {
        height: 100%;
        .rbc-row {
            height: 100%;
            .rbc-date-cell {
                height: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                position: relative;
                padding: 0;
                font-size: 14px;
                a {
                    pointer-events: none;
                }
                &.rbc-now {
                    color: #fff;
                    &.rbc-current {
                        color: #fff;
                      }
                }
            }
        }
    }
}
.rbc-month-header {
    .rbc-header {
        width: calc(100% / 7);
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0 !important;
        span {
            color: #777777;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 400;
        }
    }
}
.rbc-row {
    .rbc-event {
        position: relative;
        margin-top: 0 !important;
        top: -100% !important;
        height: 100%;
        padding: 0 !important;
        background-color: transparent !important;
        .rbc-event-content {
            text-indent: -99999px;
            &::after {
                content: "";
                height: 44px;
                width: 100%;
                position: absolute;
                background: #fb7304;
                opacity: .3;
                top: calc(50% - 22px);
                left: 0;
            }
            &::before{
                width: 2px;
                height: 44px;
                content: "";
                position: absolute;
                background: #fb7304;
                top: calc(50% - 22px);
                left: 0;
            }
            &[title="Pending"] {
                &::after {
                    background: #77D9D3;
                }
                &::before{
                    background: #77D9D3;
                }
            }
            &[title="Completed"] {
                &::after {
                    background: #a0a0a0;
                }
                &::before{
                    background: #7c7c7c;
                }
            }
        }
    }
    .rbc-row-segment {
        padding: 0;
    }
}
.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: none !important;
}
.rbc-date-cell:focus, .rbc-date-cell:active, .rbc-date-cell:hover {
    background: rgba(#fb7304, 0.1);
}
.date-in-past {
    width: 14.3%;
    background: #ccc;
    border-right: solid 1px #fff;
}
.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
    background-color: #fff !important;
}
