.filter-dropdown {
  .dropdown-menu {
    width: 310px;
    height: auto;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    padding: 30px;
    background: #fff;
    margin-top: 10px;
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-size: 15px !important;
      height: 23px!important;
      padding-top: 0px;
    }
    .react-datepicker-popper {
      padding: 2px !important;
    }
    .filter-btn-group {
      display: flex;
      justify-content: space-between;
      .btn {
        min-width: 120px;
      }
    }
  }
  .filter-btn {
    display: inline-flex;
    align-items: center;
    span {
      font-size: 18px;
      margin-right: 10px;
    }
  }
}
.filter-error {
  font-size: 11px;
  color: #f13b3b;
  position: relative;
  top: -10px;
}
.payment-history-detail-footer {
  display: flex;
  align-items: center;
  .btn-white-outline {
    height: 35px;
    width: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  @media (max-width: 640px){
    flex-wrap: wrap;
  }
}
.member-created-date {
  text-align: right;
  margin-bottom: 20px;
  @include font-mixin($font-400, 14px, #828282, normal);
  span {
    color: #3b3b3b;
  }
}
.applied-filter-info {
  width: auto;
  padding: 5px 10px;
  display: inline-flex;
  background: #ebebe9;
  border-radius: 17px;
  align-items: center;
  height: 35px;
  margin-right: 10px;
  order: 1;
  @media (max-width: 480px){
    width: 100%;
    margin-bottom: 16px;
  }
  p {
    font-size: 13px;
    margin: 0;
    line-height: normal;
    color: #4a4a4a;
  }
  button {
    line-height: normal;
    margin-left: 10px;
    display: inline-flex;
    span {
      font-size: 16px;
      color: #4a4a4a;
    }
  }
}
