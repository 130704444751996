.document-tab-body {
    height: 100%;
    position: relative;
    .d-flex-table {
        height: calc(100% - 110px);
        display: flex;
        flex-wrap: wrap;
        position: relative;
        .spinner-border {
            border: 2px solid #f3f3f3;
            border-top: 2px solid #FB7304;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            position: absolute;
            right: calc(50% - 10px);
            left: auto;
            margin: 0 auto;
            display: block;
            bottom: 46px;
        }
        &.scroll-padding {
            .table-head {
                padding-right: 15px;
            }
        }
        &.initial-padding {
            .infinite-scroll-component {
                min-height: 120px;
            }
        }
        &.history-table {
            align-content: flex-start;
            .table-body {
                padding: 0;
                height: calc(100% - 13px);
                .profile-info {
                    padding: 20px 10px 20px 40px;
                    display: flex;
                    flex-wrap: wrap;
                    border-bottom: 1px solid #dbdbdb;
                    .profile-info-col {
                        width: calc(100% / 3);
                        padding-right: 10px;
                        .value {
                            flex-wrap: wrap;
                        }
                        &.document {
                            width: 100%;
                            .material-icons-sharp {
                                font-size: 20px;
                                color: #fb7304;
                            }
                        }
                        &.notes {
                            width: 100%;
                        }
                    }
                }
            }
            .table-head {
                padding: 0;
                &.scroll-padding {
                    padding-right: 15px;
                }
            }
            .headRow {
                width: 100%;
            }
            .divRow {
                width: 100%;
                position: relative;
                .table-toggle {
                    position: absolute;
                    left: 0;
                    top: 25px;
                    display: none;
                    span {
                        font-size: 19px;
                    }
                }
                &.completed{
                    cursor: pointer !important;
                    .table-toggle {
                        display: block;
                    }
                }
            }
            .table-col {
                &.provider-col {
                    padding-left: 40px;
                }
            }
            .spinner-border {
                bottom: -64px;
            }
            .no-data-section {
                box-shadow: none;
                margin: 0 auto;
            }
            @media(max-width: 992px){
                .table-col {
                    &.provider-col {
                        padding-left: 0;
                    }
                }
                .divRow {
                    width: 100%;
                    position: relative;
                    .table-toggle {
                        left: auto;
                        top: 47px;
                        right: 0;
                    }
                }
                .table-body {
                    .profile-info {
                        padding: 10px 0;
                        margin: 0;
                        width: 100%;
                    }
                }
                .spinner-border {
                    bottom: 0;
                }
            }
        }
        .download-doc-row {
            width: 100%;
            margin-bottom: 5px;
            color: #3b3b3b;
            .doc-link {
                color: #3b3b3b;
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                &:hover {
                    outline: 0;
                    text-decoration: none;
                }
            }
        }
    }
    .table-col {
        .label--checkbox {
            line-height: normal;
            position: relative;
            height: 20px;
            width: 20px;
            display: block;
            margin: 0;
            input {
                display: none;
            }
            .checkbox {
                & + label {
                    margin: 0;
                    display: block;
                    &:after {
                        left: 0;
                        top: 0;
                    }
                    &:before {
                        left: 3px;
                        top: 4px;
                    }
                }
            }
        }
        .btn-white-outline {
            width: 40px;
            height: 40px;
            padding: 0;
            display: inline-flex !important;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            span {
                font-size: 22px;
            }
            a {
                text-decoration: none;
                &:hover, &:focus {
                    outline: 0 !important;
                    text-decoration: none;
                    span {
                        color: #fff;
                    }
                }
            }
            &:hover {
                a {
                    color: #fff;
                }
            }
            &.icon-disabled {
                background: #dedede;
                opacity: 0.6;
                &:hover, &:focus {
                    border-color: #dedede;
                }
            }
        }
        .link {
            color: #fb7304;
            text-decoration: underline;
        }
    }
    .table-head {
        padding: 0;
        height: 70px;
        width: 100%;
        .headRow {
            border-bottom: 1px solid rgba(219, 219, 219, 0.8);
        }
    }
    .table-body {
      padding: 0;
      box-shadow: none;
      height: calc(100% - 129px);
      width: 100%;
      overflow-y: auto;
      position: relative;
      border-radius: 0;
      .infinite-scroll-component__outerdiv {
        overflow: unset;
        position: relative;
        width: 100%;
        .infinite-scroll-component {
            @media (max-width: 992px) {
                display: flex;
                flex-wrap: wrap;
            }
            @media (max-width: 767px) {
            .table-column-24{ width: 100% !important;}
            }
        }
      }
      .divRow {
          cursor: default !important;
          .btn-white-outline {
            cursor: pointer;
          }
      }
    }
    .table-footer {
        height: 59px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
        .btn-white-outline {
            width: 40px;
            height: 40px;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            cursor: pointer;
            position: relative;
            &.disabled {
                opacity: 0.7;
            }
            &:disabled {
                opacity: 0.7;
            }
            span {
                font-size: 22px;
            }
            .spinner-border {
                bottom: 9px;
                right: 9px;
                width: 20px;
                height: 20px;
            }
        }
        .table-footer-row {
            display: inline-flex;
            &.spinner-box {
                .btn-white-outline {
                    background: #fb7304;
                    &:disabled {
                        opacity: 1;
                    }
                }
            }
        }
    }
    &.no-list-data {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .upload-doc {
            min-height: 210px;
        }
    }
    .full-page-spinner{
        background: rgba(255, 255, 255, .9);
    }
    @media(max-width: 992px){
        .table-body {
            width: 100% !important;
            .infinite-scroll-component__outerdiv {
                width: 100%;
                .infinite-scroll-component {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    .divRow {
                        width: 100% !important;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}
#superbillDiv {
    overflow-y: scroll;
    a {
        color: #fb7304;
    }
    canvas {
        border: 1px solid #E7F1FD;
    }
    .form-control {
        background-color: transparent;
    }
}
.upload-doc {
    .document-upload-btn {
        padding: 0;
        height: 40px;
        line-height: 40px;
        min-height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        input {
            display: none;
        }
        .material-icons-sharp {
            font-size: 22px;
            margin-right: 5px;
        }
    }
}
.doctor-completed-appointment-box, .patient-completed-appointment-box {
    .body {
        border-bottom: 1px solid #e2e2e2;
        .view-history-heading {
            @include font-mixin($font-500, 16px, rgb(60, 60, 60), normal);
            margin-bottom: 15px;
            padding: 0 15px;
        }
    }
    .notes-heading-btn {
        display: flex;
        justify-content: space-between;
        @include font-mixin($font-500, 14px, rgb(60, 60, 60), normal);
        margin-bottom: 5px;
        .btn {
            padding: 0;
            min-height: 10px;
            min-width: auto;
            border: 0;
            line-height: normal;
        }
    }
    textarea {
        width: 100%;
        border-color: #e2e2e2;
        min-height: 100px;
        resize: none;
        border-radius: 5px;
        padding: 10px 15px;
        font-size: 14px;
        color: #6e6d6d;
    }
    .border-box {
        .w-100 {
            border: 1px solid #e9e9e9;
            padding: 15px;
            border-radius: 5px;
            p {
                max-height: 140px;
                overflow-y: auto;
            }
        }
    }
    .no-notes-text {
        width: 100%;
        padding: 0 15px;
        @include font-mixin($font-500, 14px, #777, normal);
        margin: 0;
        &.border-bottom{
            border-bottom: 1px solid #f7f7f7 !important;
        }
    }
    .tab-section {
        .nav {
            @media(max-width: 992px){
                &.nav-tabs {
                    background: transparent;
                    position: relative;
                    top: 0;
                    left: 0;
                    margin: 0;
                    display: flex;
                    height: auto;
                    width: auto;
                    flex-wrap: nowrap;
                    li {
                        a {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

}
