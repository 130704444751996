@import '../scss/fonts';
@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/bass';
@import '../scss/helpers';
.dashboard-page{
  height: calc(100vh - 130px);
  overflow: auto;
  background:#fafaf2;
  padding-bottom: 32px;
  @media(max-width:992px){
    height: auto;
    margin-top: 70px;
  }
}
.detail-info{
  padding: 0 0 50px;
  @media(max-width:992px){
    padding-bottom: 35px;
  }
  &.doctor-schedule-profile {
    padding: 0 0 40px;
    .profile-info {
      .box {
        min-height: auto;
      }
      .user-info {
        padding-bottom: 0;
        .head {
          align-items: center;
          margin-bottom: 0;
          @media(max-width:992px){
            padding-left: 5px;
          }
          .title-text {
            width: 370px;
            flex: none;
            @media(max-width:992px){
              width: 100%;
              padding-left: 10px;
            }
          }
          .profile-info {
            width: calc(100% - 370px);
            .value {
              margin-bottom: 0;
            }
            @media(max-width:992px){
              width: 100%;
              margin-top: 15px;
              .value {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.text-value{
  @include font-mixin($font-600, 1.4rem, $theme-orange-color, normal);
  margin-left: 8px;
}
.welcome{
  h1{
    @include font-mixin($font-600, 1.8rem, #4a4a4a, normal);
    margin-top:32px;
    margin-bottom: 20px;
    @media(max-width:767px){
      font-size:15px
    }
    span{
      @include font-mixin($font-400, 1.6rem, $theme-orange-color, normal);
      @media(max-width:767px){
        font-size: 14px
      }
    }
  }
}
.profile-links{
  padding: 15px 0;
  ul{
    list-style: none;
    display:flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px;
    @media(max-width:767px){
      flex-direction: column;
    }
    li{
      text-align: center;
      margin: 15px 5px;
      max-width: 220px;
      width: 100%;
      @media(max-width:767px){
        max-width:100%;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 0;
        margin-right: 0;
      }
      &.subscription-link-tab {
        .link-icon {
          cursor: pointer;
        }
      }
      a, .link-icon {
        display: inline-block;
        cursor: default;
        @media(max-width:767px){
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .profile-status{
          height: 40px;
          width: 40px;
          margin: 0 auto 20px;
          border: solid 5px #fff;
          background: #dddcdc;
          border-radius:50%;
          transition: all .3s ease 0s;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
          display:flex;
          align-items:center;
          justify-content: center;
          @media(max-width:767px){
            margin: 0 15px 0 0px;
            height: 34px;
            width: 34px;
          }
          span{
            font-size: 18px;
            color:#fff;
            display: none;
          }
        }
        &.active{
          .profile-status{
            background:$theme-orange-color;
            span{
              display: block;
            }
          }
        }
        >span{
          @include font-mixin($font-500, 1.4rem, #777, normal);
          i{
            color:#626262;
            font-size: 13px;
          }
        }
        &:hover{
          text-decoration: none;
          .profile-img{
            background:#77d9d3;
          }
        }
      }
    }
  }
}
.mt--10 {
  margin-top: -10px;
}
.card-box{
  background:$white;
  min-height: auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  @media (max-width:992px){
    margin-bottom: 15px;
  }
  @media (max-width:767px){
  padding: 20px 15px;
  }
  &.dashbaord-all-apointment-section {
    position: relative;
    .appointments{
      height: 430px;
      overflow-y: auto;
    }
    .view-all-section{
      position: absolute;
      bottom:0;
      left:0;
      width: 100%;
      background-color:#fff;
      padding: 15px 30px 15px;
      text-align: right;
      border-radius: 0 0 8px 8px;
      a{
        font-family: $font-600;
        font-size: 1.4rem;
        color: $theme-orange-color;
        font-weight: normal;
      }
    }
    .profile-info  {
      .box {
        padding: 0;
        box-shadow: none;
        border-bottom: 1px solid #f0f0f1;
        margin-bottom: 30px;
        min-height: auto !important;
        &:only-child, &:last-child {
          border-bottom: 0;
        }
        h4 {
          @include font-mixin($font-600, 16px, #090706, normal);
          margin: 0 0 25px 0;
        }
        div {
          border: 0;
          padding: 0;
        }
        .user-info {
          border-bottom: 0;
          padding-right: 20px;
          align-items: flex-start;
          .title-text {
            margin-right: 0;
            .datetime {
              p {
                font-size: 13px;
              }
            }
          }
        }
        .action-status {
          &.row-action-status {
            flex-wrap: wrap;
            justify-content: flex-end;
            max-width: 170px;
            @media(max-width:767px){
              justify-content: flex-start;

            }
            @media (max-width:600px){
              max-width: 100%;
              .btn-white-outline {
                margin-bottom: 0 !important;
              }
            }
            .btn-white-outline {
              margin-bottom: 10px;
            }
            .btn {
              min-width: 130px;
              padding: 0px 10px;
              font-size: 12px;
              &.outline {
                min-height: 35px;
              }
            }
          }
        }
      }
    }
    .no-data-section {
      box-shadow: none;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      h2{
        width: 100%;
      }
    }
    .completed-appointment-box {
      .button-row {
        display: none;
      }
      .body {
        display: none;
      }
    }
  }
}
.patient-action-status {
  .btn {
    font-size: 13px;
    &.btn-outline {
      background: #fff;
      color: #FB7304;
      min-height: 40px;
      &:focus, &:hover, &:active {
        background: #fff !important;
        color: #FB7304;
        box-shadow: none;
        border-color:#FB7304;
      }
    }
    &.btn-pending {
      background: #77D9D3;
      color: #fff;
      min-height: 40px;
      margin-bottom: 15px;
      border-color:#77D9D3;
      border-radius: 20px !important;
      &:focus, &:hover, &:active {
        background: #77D9D3 !important;
        color: #fff;
        box-shadow: none;
        border-color:#77D9D3;
      }
    }
  }
}
.card-row{
  border-bottom: 1px solid #f0f0f1;
  padding-bottom: 10px;
  padding-top: 30px;
  @media (max-width:992px){
    .col-lg-2{
      &.justify-content-end{
        justify-content:flex-start !important;
      }
    }
  }
  &:first-child{
    padding-top: 0;
  }
  &:last-child{
    padding-bottom: 0;
    border: 0;
  }
}

.activity{
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      border-bottom: 1px solid rgba(219, 219, 219, 0.8);
      padding: 22px 0 22px;
      display:flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      transition: all .3s ease 0s;
      span{
        &:first-child{
          color: #777;
          margin-right: 18px;
        }

      }
      &:first-child{
        padding-top: 16px;
      }
      .name{
        border: 0;
        @include font-mixin($font-400, 1.4rem, #777, normal);
      }
      &:hover{
        transition: all .3s ease 0s;
        span {
          &:first-child{
            color: $theme-orange-color;
          }
        }
        .name{
          color: #090706;
        }
      }
    }
  }
}

.profile-info{
  .box{
    background:$white;
    min-height: 385px;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    @media (max-width:992px){
      margin-bottom: 15px;
      min-height: auto;
    }
    @media (max-width:767px){
      padding: 20px 15px;
      }
    .head{
      margin-bottom: 20px;
      display:flex;
      align-items: self-start;
      justify-content:space-between;
      @media(max-width:767px){
        flex-direction: column;
      }
      .title{
        @include font-mixin($font-500, 1.6rem, #4a4a4a, normal, normal);
      }
      .action-status{
       display:flex;
       align-items: center;
       h4 {
         margin-right: 10px;
       }
       @media(max-width:767px){
         margin-top: 15px;
       }
      }
    }
  }
  .profile-img{
    width: 80px;
    height: 80px;
    margin-right: 15px;
    border-radius: 50%;
    flex: none;
    @media(max-width:767px){
      width: 50px;
      height: 50px;
    }
    &.active{
      position: relative;
      &:after{
        content: "";
        position: absolute;
        right: 5px;
        top: 60px;
        height: 17px;
        width: 17px;
        border-radius: 50%;
        background: #4dd966;
        border: 2px solid #fff;
        @media(max-width:767px){
          top:50px;
          height: 15px;
          width: 15px;
        }
      }
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  label{
    @include font-mixin($font-400, 1.2rem, #777, normal, 18px);
    margin-bottom: 0;
    display: block;
  }
  .value{
    @include font-mixin($font-500, 1.3rem, #3b3b3b, normal, 18px);
    margin-bottom: 20px;
    .chip{
      background:#f2f2f2;
      border-radius: 15px;
      padding: 3px 10px;
      @include font-mixin($font-500, 1.0rem, #3b3b3b, normal, 18px);
    }
    .view-doc {
      @include font-mixin($font-600, 14px, #fb7304, normal);
      top: 3px;
      position: relative;
      cursor: pointer;
    }
    span {
      &:not(:nth-child(2)) {
        em {
          &::after {
            content: ",";
          }
        }
      }
    }
    &.more-items {
      span {
        display: inline-block;
        margin-right: 5px;
        &:only-child {
          em {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  .profile-section-head {
    width: 100%;
    margin: 10px 0 20px;
    padding: 0 15px;
    @include font-mixin($font-600, 1.8rem, #4a4a4a, normal);
  }
  h4{
    @include font-mixin($font-400, 1.4rem, #777, normal);
    margin: 0;
    span{
      color: #3b3b3b;
    }
  }
}
.user-info{
  padding-bottom: 15px;
  display:flex;
  .head{
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    margin-bottom: 15px;
    .title-text{
      margin-right: 15px;
      flex: 1;
      h1{
        @include font-mixin($font-600, 2rem, #090706, normal);
      }
      h2{
        @include font-mixin($font-400, 1.4rem, #777, normal);
        span{
          color: #3dae51;
          margin-left: 8px;
        }
      }
      h3{
        @include font-mixin($font-400, 1.4rem, #777, normal);
        margin-top: 12px;
        margin-bottom: 0;
        &.d-flex{
          flex-wrap: wrap;
        }
        &.matching-percentage {
          span {
            @include font-mixin($font-600, 1.4rem,#FB7304, normal);
            margin-left: 4px;
          }
        }
      }
      &.matched-doctor-title {
        h3{
          margin-top: 5px;
          &.mt-10 {
            margin-top: 0 !important;
          }
        }
      }
    }
  }

}
.rating-section{
  .user-info{
    padding-bottom: 15px;
    display:flex;
    .head{
      margin-bottom: 0;
      h1{
        font-size:16px;
        font-family: $font-500;
      }
      .title-text{
        margin-right: 0;
      }
      p{
        margin-bottom: 5px;
        margin-top: 0;
        color: #3b3b3b;
      }
    }

  }
}
.heading-link{
  display:flex;
  justify-content: space-between;
  align-items:color;
  margin-bottom: 20px;
  a{
    &:hover{
      text-decoration:none;
    }
  }
  &.appointment_heading-link {
    a {
      @include font-mixin($font-600, 1.4rem, #FB7304, normal);
    }
  }
  h5{
    @include font-mixin($font-600, 1.8rem, #4a4a4a, normal);
    @media(max-width:767px){
      font-size:16px;
    }
  }
  span{
    @include font-mixin($font-600, 1.4rem, $theme-orange-color, normal);
  }
}
.my-matched-section{
  margin-bottom: 50px;
  .value{
    margin-bottom: 10px;
  }
  @media(max-width:992px){
    .col-lg-2{
      margin: 20px 0;
    }
  }
  .rbc-calendar {
    .rbc-day-bg {
      &.date-in-past {
        border-right: 0;
      }
    }
  }
}

.table-section{
  table{
    margin: 0;
    width: 1080px;
    tr{
      th{
        padding:15px 5px;
        border: 0;
        .data-name{
          display: flex;
          align-items: center;
          strong{
            @include font-mixin($font-500, 1.2rem, #777, normal);
            text-transform: uppercase;
          }
          span{
            font-size:16px;
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }
      td{
        padding: 25px 5px;
        @include font-mixin($font-500, 1.4rem, #3b3b3b, normal);
      }
    }
    thead{
      th{
        border: 0;
        .check-btn{
          position: relative;
          top: -10px;
        }
      }
    }
  }
  .heading{
    padding: 10px 15px 0;
    th{
      border: 0;
    }
  }
  .box{
    @media(min-width: 991px){
      border-radius: 8px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
      min-height: 200px;
      background-color: #fff;
      padding: 0 15px;
      margin-top: 15px;
    }
    .tbody{
      tr{
        border-bottom: 1px solid rgba(219, 219, 219, 0.8);
        &:hover, &.active{
          background-color:rgba(119,119,119, 0.1);
          cursor: pointer;
        }
      }
      td{border:0;}
    }
  }
}
.mobile-checkbox{
  display: none;
  @media (max-width: 992px){
      display:block;
  }

}
.divTable {
  display: table;
  width: 100%;
  @media (max-width: 992px){
    flex-wrap: wrap;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    width: auto;

  }
  .headRow {
    display: flex;
    align-items: center;
    width: auto;
    clear: both;
    @media (max-width: 992px){
      display:none;
    }
    .divCell{
      display:flex;
      align-items: center;
      justify-content:space-between;
      padding: 20px 5px 20px;
      &:nth-child(1){
        width: 4%;
        padding-top: 5px !important;
      }
      strong{
        @include font-mixin($font-500, 1.2rem, #777, normal, normal);
        text-transform: uppercase;
      }
      span{
        font-size:16px;
        margin-left: 4px;
        cursor: pointer;
      }
    }
    .table-col{
      display:flex;
      align-items: center;
      justify-content:space-between;
      padding: 0 10px 0 0;
      strong{
        @include font-mixin($font-500, 1.2rem, #777, normal, normal);
        text-transform: uppercase;
      }
      span{
        font-size:16px;
        margin-left: 4px;
        cursor: pointer;
      }
    }
  }

    .divRow {
      display: flex;
      width: auto;
      clear: both;
      border-bottom: 1px solid rgba(219, 219, 219, 0.8);
      word-wrap: break-word;
      &:hover, &.active{
        background-color:rgba(119,119,119, 0.1);
        cursor: pointer;
      }
      .divCell{
        padding: 25px 5px;
        @include font-mixin($font-500, 1.4rem, #3b3b3b, normal, normal);
      }
      .table-col{
        padding: 0 10px 0 0;
        @include font-mixin($font-500, 1.4rem, #3b3b3b, normal, normal);
      }
      @media (max-width: 992px){
        border-bottom: 0;
        flex-wrap: wrap;
        width: calc(50% - 30px) !important;
        border-radius: 8px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
        background-color: #fff;
        padding: 0 15px;
        margin: 15px;
        .divCell, .table-col {
          width: 50% !important;
          padding: 15px 5px;
          flex-wrap: wrap;
          &[data-label="Email Address"]{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &::before{
            content: attr(data-label);
            @include font-mixin($font-500, 1.2rem, #777, normal, normal);
            display:block;
            margin-bottom: 5px;
            width: 100%;
          }
        }
      }
      @media (max-width: 767px){
        width: calc(100% - 30px) !important;
      }
    }

    .divCell {
      float: left; /* fix for  buggy browsers */
      display: table-cell;
      &:nth-child(1){
        width: 4%;
        padding-top: 11px;
      }
      &:nth-child(2){
        width: 10%;
      }
      &:nth-child(3){
        width: 10%;
      }
      &:nth-child(4){
        width: 10%;
      }
      &:nth-child(5){
        width: 10%;
      }
      &:nth-child(6){
        width: 10%;
      }
      &:nth-child(7){
        width: 12%;
      }
      &:nth-child(8){
        width: 12%;
      }
      &:nth-child(9){
        width: 14%;
      }
      &:nth-child(10){
        width: 8%;
      }
    }
    &.doc{
      .divCell{
        padding: 16px 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &:nth-child(1){
          width: 10%;
          padding-top: 0;
        }
        &:nth-child(2){
          width: 20%;
        }
        &:nth-child(3){
          width: 23%;
        }
        &:nth-child(4){
          width: 23%;
        }
        &:nth-child(5){
          width: 24%;
        }
      }
    }
    &.patient-payment-table{
      @media (max-width: 480px){
        .divCell{
          width: 100% !important;
        }
      }
      .divRow{
        &:hover{
          background-color:transparent;
          cursor: auto;
        }
      }
      .divCell{
        padding: 16px 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:nth-child(1){
          width: 20%;
          padding-top: 16px !important;
        }
        &:nth-child(2){
          width: 20%;
        }
        &:nth-child(3){
          width: 20%;
        }
        &:nth-child(4){
          width: 25%;
        }
        &:nth-child(5){
          width: 15%;
          justify-content: center;
          @media(max-width:992px){
            justify-content: flex-start;
          }
        }
      }
    }
    &.doctor-payment-table{
      .sub-accoridon{
        border-bottom: 1px solid rgba(216,216,216, 0.5);
        width: 100%;
        .profile-info {
          label {
            text-transform: uppercase;
          }
          .value {
            font-size: 14px;
          }
        }
        @media (max-width: 992px){
          width: calc(50% - 10px);
          margin: 10px 0;
          border: 0;
          .divRow {
            width: 100% !important;
            margin: 0 !important;
          }
        }
        @media (max-width: 640px){
          width: 100%;
        }
      }
      .divRow{
        border-bottom: 0;
      }
      .divCell{
        padding: 16px 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    &.history{
      .divCell{
        padding: 16px 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &:nth-child(1){
          width: 10%;
        }
        &:nth-child(2){
          width: 25%;
        }
        &:nth-child(3){
          width: 45%;
        }
        &:nth-child(4){
          width: 20%;
        }
      }
    }
  }
/* edit profile */
.back-link{
  display:flex;
  align-items:center;
  margin: 20px 0;
  &.doctor-schedule-back-link {
    margin: 25px 0;
    width: 100%;
  }
  a{
    background:transparent;
    padding: 7px 7px;
    span{
      color: #777;
    }
  }

  h1{
    @include font-mixin($font-600, 1.8rem, #4a4a4a, normal);
    margin-bottom: 0;
  }
}
.middle-content{
  height: calc(100vh - 130px);
  overflow: auto;
  background:#fafaf2;
  margin-bottom: 32px;
  .upload-profile-image{
    margin-top: 24px;
  }
  &.zoom-session-page {
    height: auto;
    overflow: unset;
    margin-bottom: 0;
    min-height: 459px;
    .detail-info {
      padding: 0 0 40px 0 !important;
      .box {
        .user-info-detail {
          min-height: 148px;
        }
      }
    }
    .spinner-border {
      background: transparent;
    }
  }
  @media(max-width:767px){
    height: auto;
    margin-top: 70px;
  }
  &.edit-profile{
    padding-bottom: 60px;
    .form-btn-group {
      .btn {
        flex: none;
        &.cancel-btn {
          line-height: 35px;
        }
      }
    }
    @media(max-width:992px){
      .back-link {
        margin-left: 40px;
      }
    }
    &.user-right-container {
      position: relative;
      padding-left: calc((100% - 1110px) / 2 + 380px);
      padding-top: 80px;
      padding-right: calc((100% - 1110px) / 2);
      .form-step{
        max-width: 100%;
      }
      @media(max-width:1100px){
        padding-left:  386px;
        padding-right: 15px;
      }
      @media(max-width:992px){
        padding-left:  15px;
        padding-top: 80px;
      }
      .summary {
        .detail {
          &:nth-child(8) {
            border-bottom: 0;
          }
        }
      }
      .doctor-user-profile {
        .middle {
          padding-bottom: 0;
        }
      }
      .change-password-form {
        max-width: 410px;
        width: 100%;
        margin: 0 auto;
      }
      .background-info-wrapper {
        .form-check {
          padding: 15px 15px 10px 15px;
          border-radius: 8px;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
          background-color: #ffffff;
          margin-bottom: 20px;
          .multi-radio {
            span {
              width: 100%;
              display: block;
              margin-bottom: 8px;
              color: #777777;
              font-size: 14px;
            }
          }
          .label--radio {
            box-shadow: none;
            display: inline-block;
            margin-right: 15px;
            margin-left: 15px;
            label {
              pointer-events: none;
            }
          }
        }
      }
    }
  }
  &.terms-policy {
    height: calc(100vh - 60px);
    .static-page-content {
      padding-bottom: 50px;
      @include font-mixin($font-400, 14px, #4a4a4a, normal);
    }
    @media (max-width: 992px) {
      margin: 0;
    }
  }
  &.static-page-container {
    .back-btn-icon {
      position: relative;
      .back-link {
        position: absolute;
        top: 55px;
        left: 0;
        margin: 0;
        @media (max-width: 992px) {
          left: 15px;
        }
      }
    }
  }
}

.max-w-100{
  max-width: 100%;
}
.edit-nav{
  padding: 15px 30px 15px;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-height: calc(100vh - 230px);
  min-height: calc(100vh - 230px);
  margin-bottom: 0;
  overflow-y: auto;
  @media(max-width:992px){
    position: fixed;
    background: #fff;
    z-index: 13;
    height: 100vh;
    padding: 15px 15px 30px;
    width: 260px;
    left: -260px;
    margin-top: -80px;
    transition: all .3s ease 0s;
    &.open{
      left:0;
    }
  }
  .nav-toggal{
    display:none;
    transition: all .3s ease 0s;
    @media(max-width:992px){
      display:block;
      position: absolute;
      right: -40px;
      top: 20px;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 0 50% 50% 0;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      color: $theme-orange-color;
    }
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      border-bottom: 1px solid rgba(219, 219, 219, 0.8);
      cursor: pointer;
      transition: all .3s ease 0s;
      a, .logout-btn {
        padding: 15px 0 15px;
        display:flex;
        align-items: center;
        justify-content: flex-start;
        border:0;
        text-transform: none;
        &:hover, &:focus {
          text-decoration: none;
          outline: none;
        }
        span{
          &:first-child{
            color: #777;
            margin-right: 18px;
          }
        }
      }
      &:first-child{
        padding-top: 0;
      }
      .name{
        border: 0;
        @include font-mixin($font-400, 1.4rem, #777, normal);
      }
      &:hover, &.active{
        transition: all .3s ease 0s;
        span {
          &:first-child{
            color: $theme-orange-color;
          }
        }
        .name{
          color: #090706;
        }
      }
    }
  }
}
.faq-nav {
  .edit-nav {
    max-height: 100%;
    min-height: auto;
    ul {
      li {
        padding: 15px 0 15px;
      }
    }
    @media(max-width:768px){
      overflow-y: unset;
    }
  }
}
.moreItemTooltiip {
  .tooltip-inner {
    padding: 8px !important;
    em {
      margin-right: 8px;
      display: inline-block;
    }
  }
}
.no-data-section {
  background: #FFFFFF;
  min-height: auto;
  padding: 50px 30px;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .05);
  text-align: center;
  h2 {
    @include font-mixin($font-600, 16px, #3b3b3b, normal);
    margin: 20px 0 0 0;
  }
  p {
    @include font-mixin($font-400, 14px, #777, normal);
    margin: 10px 0 25px 0;
  }
}
.subscriptionPlan{
  .tab-section{
    position: relative;
    .nav-tabs{
      position: absolute;
      right: 0;
      top: 0;
      border: 0;
      padding: 5px;
      border-radius: 24px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      .nav-item{
        .nav-link{
          @include font-mixin($font-500, 1.2rem, #4a4a4a, normal);
          text-transform: uppercase;
          padding: 4px 0 1px;
          border-radius: 24px;
          min-width: 96px;
          text-align: center;
          transition: all .3s ease 0s;
          border: 0;
          &.active, &:hover{
            background-color: $theme-orange-color;
            color: #fff;
          }
        }
      }
    }
    .tab-content{
      padding-top: 30px;
    }
    .box{
      background:$white;
      border-radius: 8px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
      min-height: 526px;
      margin-bottom: 24px;
      &.recommended{
        margin-top: -32px;
        @media(max-width:767px){
          margin-top: 0;
        }
      }
      .planDetail{
        padding: 30px 15px;
        text-align: center;
        .planMostRecommended{
          background-color: #77d9d3;
          padding: 10px 15px 9px;
          border-radius: 14px;
          @include font-mixin($font-700, 1rem, #fff, normal, normal);
          text-transform: uppercase;
          max-width: 145px;
          margin-bottom: 20px;
          margin-left: auto;
          margin-top: -15px;
        }
        .planName{
          @include font-mixin($font-500, 1.4rem, #777, normal);
        }
        .planHeading{
          @include font-mixin($font-700, 2rem, #4a4a4a, normal);
          text-transform: uppercase;
          margin-top: 8px;

        }
        .planAmount{
          margin-top: 15px;
          display: flex;
          justify-content: center;

          span{
            color: $theme-orange-color;
            &:first-child{
              font-size: 24px;
              font-family: $font-400;
              margin-top: 8px;
            }
            &:last-child{
              font-size: 60px;
              font-family: $font-600;
              line-height:normal;
              @media(max-width:767px){
                font-size:40px;
              }
            }
          }
        }
        .planDate{
          @include font-mixin($font-500, 1.4rem, #777, normal);
        }
      }
      .activity{
        border-top: 1px solid rgba(219, 219, 219, 0.8);
        padding: 10px 30px 30px;
        ul {
          margin-bottom: 20px;
          li{
            &:last-child{
              border: 0;
            }
            .name{
              @include font-mixin($font-500, 1.4rem, #666, normal);
            }
          }
        }
      }
    }
  }
}
.question-box-group {
  width: calc(100% - 30px);
  padding: 0;
  margin: 25px auto 35px auto;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  .question-box-row {
    display: flex;
    justify-content: space-between;
    p {
      width: calc(100% - 94px);
      padding-left: 15px;
      margin: 0;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
    }
    span {
      width: 94px;
      flex: none;
      text-align: center;
      height: 45px;
      line-height: 45px;
      font-size: 14px;
      border-left: 1px solid #ebebeb;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }
  }
}
.open-image-cropper {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #777777;
  padding: 10px 20px;
  border-radius: 8px;
  border: solid 1px rgba(219, 219, 219, 0.8);
  cursor: pointer;
  input {
    display: none;
  }
}
.upload-profile-image {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  margin-bottom: 40px;
  .profile-image {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    overflow: hidden;
    flex: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .upload-action {
    width: auto;
    padding-left: 20px;
    padding-top: 10px;
    .col-sm-12{
      padding: 0;
    }
    .custom-file-link {
      label {
        &:before {
          content: attr(data-label);
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 600;
          color: #777777;
          padding: 10px 20px;
          border-radius: 8px;
          border: solid 1px rgba(219, 219, 219, 0.8);
        }
      }
      &.hidden-label {
        label {
          &::before {
            display: none;
          }
        }
        .red-text-color2 {
          left: -154px !important;
          top: -24px !important;
        }
      }
      span {
        display: none;
        &.col-sm-6 {
          display: inline-block;
          width: 300px;
          @media(max-width:992px){
            width: 170px;
          }
        }
        &.red-text-color2 {
          display: inline-block;
          p {
            margin: 0;
            line-height: 18px;
          }
          &.ml-8 {
            width: 30px;
            height: 30px;
            background: #fff;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
            border-radius: 50%;
            text-align: center;
            line-height: 30px;
            position: absolute;
            top: 5px;
            left: -50px;
            opacity: 1;
            color: rgba(0, 0, 0, .3) !important;
            font-size: 18px;
          }
        }
      }
    }
    &.file-uploaded {
      .custom-file-link {
        label {
          &::before {
            display: none;
          }
        }
      }
    }
  }
  .btn-close {
    width: 30px;
    height: 30px;
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    position: absolute;
    top: 43px;
    left: 65px;
    opacity: 1;
    color: rgba(0, 0, 0, .3);
    font-size: 18px;
  }
}
.edit-profile-btn {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: -60px;
  right: 0;
  .btn-white-outline {
    padding: 0;
    line-height: normal;
    span {
      padding: 8px;
      font-size: 22px;
      cursor: pointer;
    }
  }
}
.image-upload-container {
  display: flex;
  align-items: center;
  .open-image-cropper {
    margin-right: 15px;
  }
  .form-group {
    width: 170px;
    .progressBarWrapper {
      width: 100%;
    }
    @media(max-width:992px){
      width: 100px;
    }
  }
}
.providers-section{
  @media (max-width:449px){
    .heading-link{
      flex-direction: column;
      h5{
        margin-bottom: 0;
      }
      .btn{
        margin-right: 0;
        margin-top: 15px;
        width: 100%;
      }
    }
  }

}
.panding-req{
  @include font-mixin($font-500, 1.3rem, #3b3b3b, normal, 18px);
  padding: 10px 24px;
  border-radius: 17px;
  background-color: rgba(119,119,119, 0.1);
}
.all-appointments{
  .user-info{
      border-bottom: 1px solid rgba(216,216,216, 0.5);
      align-items: center;
      padding-bottom: 35px;
      margin-bottom: 30px;
      @media(max-width:992px){
          align-items: flex-start;
      }
      .head{
          margin-bottom: 0 !important;
          align-items: center;
      }
  }
  .action-status{
      flex-direction: column;
      button{
        min-width: 192px;
      }
      .btn-link{
          border: 0;
          background-color: transparent;
          text-transform: uppercase;
          text-decoration:none;
          @include font-mixin($font-600, 1.4rem, #999, normal, 18px);
          &:hover{
              text-decoration:none;
          }
      }
  }
}
.appointments{
  .user-info{
      border-bottom: 1px solid rgba(216,216,216, 0.5);
      align-items: center;
      padding-bottom: 35px;
      margin-bottom: 30px;
      @media(max-width:992px){
          align-items: flex-start;
      }
      .head{
          margin-bottom: 0 !important;
          align-items: center;
      }
  }
  .action-status{
      flex-direction: column;

      .btn-link{
          border: 0;
          background-color: transparent;
          text-transform: uppercase;
          text-decoration:none;
          @include font-mixin($font-600, 1.4rem, #999, normal, 18px);
          &:hover{
              text-decoration:none;
          }
      }
  }
  &.doctor-appointment-list {
    .profile-info {
      padding-bottom: 40px;
      .box {
        min-height: 200px;
      }
    }
    .user-info {
      &:last-child, &:only-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
      .head {
        align-items: flex-start;
        .title-text {
          h1 {
            span {
              font-size: 12px;
              color: #777;
            }
          }
          h3 {
            margin: 0 0 5px 0;
            display: flex;
            .star-rating {
              line-height: normal;
              margin-left: 6px;
              display: flex;
            }
          }
        }
      }
    }
    .action-status {
      &.row-action-status {
        flex-direction: row;
        .btn-white-outline {
          padding: 0 7px;
          height: 39px;
          width: 39px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        &.upcoming-appointment-action-status {
          display: flex;
          max-width: 246px;
          justify-content: flex-end;
          flex-wrap: wrap;
          .btn {
            &.outline {
              margin-top: 10px;
              min-height: 40px;
            }
          }
        }
      }
      .btn {
        &.btn-primary {
          &.btn-primary-pending {
            background: #77d9d3;
            color: #fff;
            border-color: #77d9d3;
          }
        }
      }
    }
  }
}
.datetime{
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  margin-top: 5px;
  .material-icons{
      margin-right: 5px;
      color: #777;
      font-size:20px;
  }
  p{
      @include font-mixin($font-600, 1.4rem, #666, normal, 18px);
      margin-top: 2px;
      margin-bottom: 0;
      @media(max-width:600px){
        font-size: 12px !important;
      }
  }
}

.purchase-card{
  .profile-info{
      .box{
          min-height: auto;
          margin-top: 15px;
      }
      .user-info{
          border-bottom: 1px solid rgba(216,216,216, 0.5);
          align-items: center;
          padding-bottom: 35px;
          margin-bottom: 30px;
          @media(max-width:992px){
              align-items: flex-start;
          }
          .head{
              margin-bottom: 0 !important;
              align-items: center;
              @media(max-width:992px){
                  .price-sec, .title-text{
                      width: 100%;
                      max-width: 100%;margin-top: 15px;
                      margin-right: 0;
                  }
              }
          }
      }
      .action-status{
          justify-content: flex-end;
          text-align: right;
          &.patient-scheduled-appointment-actions {
            @media(max-width:480px){
              justify-content: flex-start;
              text-align: left;
              flex-direction: column;
              .mr-15{
                margin-bottom: 15px;
                }
              }
            .btn {
              &.btn.btn-secondary {
                &:disabled {
                  background: #999999;
                  opacity: 1;
                  color: #fff;
                  border: 1px solid #999999;
                  &:hover, &:focus, &:active {
                    background: #999999 !important;
                    border: 1px solid #999999 !important;
                    color: #fff !important;
                  }
                }
              }
            }
          }
      }
  }
  @media(max-width:640px){
    .d-flex {
      &.dropdown-btn {
        flex-wrap: wrap;
        .dropdown {
          margin-bottom: 15px;
        }
      }
    }
  }
}
.history-and-records{
  .profile-info{
      .price-sec{
          max-width: 320px;
          flex: 1 1;
      }
      .box{
          min-height: auto;
          margin-top: 15px;
      }
      .user-info{
          align-items: center;
          padding-bottom: 35px;
          @media(max-width:992px){
              align-items: flex-start;
          }
          .head{
              margin-bottom: 0 !important;
              align-items: center;
              .title-text {
                  flex: initial;
                  @media(max-width:992px){
                      width: 100%;
                      margin-right: 0;
                  }
              }
              @media(max-width:992px){
                  .price-sec{
                      width: 100%;
                      max-width: 100%;margin-top: 15px;
                  }
              }
          }
      }
      .action-status{
          justify-content: flex-end;
          text-align: right;
      }
  }
  .button-row{
      border-bottom: 1px solid rgba(216,216,216, 0.5);
      border-top: 1px solid rgba(216,216,216, 0.5);
      display:flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 30px;
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: 30px;
      .view-history{
          @include font-mixin($font-600, 1.4rem, $theme-orange-color, normal, 18px);
          display:flex;
          align-items:center;
          cursor: pointer;
          text-transform: uppercase;
          .material-icons{
              margin-left: 5px;
          }
          @media(max-width:600px){
            font-size: 12px;
          }
      }
  }
  .body{
      margin-top: 30px;
      .row{
          margin-bottom: 50px;
      }
      label{
          margin-top: 20px;
      }
      .value{
          margin-top: 5px;
          margin-bottom: 0;
      }
      .payment-button{
          display:flex;
          justify-content: space-between;
          align-items:center;
      }
  }
  &.doctor-appointment-list {
    .completed-appointment-box {
      margin-bottom: 40px;
      .button-row {
        border-top: 0;
      }
      .user-info {
        border-bottom: 1px solid rgba(216, 216, 216, 0.5);
        &:last-child, &:only-child {
          border-bottom: 0;
          padding-bottom: 0;
        }
        .head {
          align-items: flex-start;
          .title-text {
            h1 {
              span {
                font-size: 12px;
                color: #777;
              }
            }
            h3 {
              margin: 0 0 5px 0;
              display: flex;
              .star-rating {
                line-height: normal;
                margin-left: 6px;
                display: flex;
              }
            }
          }
        }
      }
      .body {
        display: none;
        &.show {
          display: block;
        }
      }
    }
  }
}
.price-sec{
  display:flex;
  justify-content: space-between;
  align-items:center;
  max-width:300px;
  flex:1;
  .price{
      text-align: right;
      h5{
          @include font-mixin($font-600, 4rem, $theme-orange-color, normal);
          margin-bottom: 0;
          position: relative;
          display: inline-block;
          span{
              font-size:18px;
              position:absolute;
              top: 5px;
              left: -10px;
          }
      }
      p{
          @include font-mixin($font-500, 1.4rem, #777, normal, 18px);
          margin-bottom: 0;
      }
  }
}

.PatientMatchedDoctors{
  .my-matched-section{
    margin-bottom: 0;
  }
  .col-lg-4{
    flex: 0 0 50%;
    max-width: 50%;
    @media(max-width:600px){
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
    order: 3;
    @media(max-width:992px){
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .col-lg-2 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px !important;
    padding-left: 15px !important;
    align-items: flex-start !important;
    @media(max-width:600px){
      flex: 0 0 100%;
      max-width: 100%;
    }
    .action-status{
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 15px;
      @media(max-width:600px){
        margin-bottom: 0;
      }
    }
  }
}
.completed-search{
  .dropdown-btn {
    .btn{
      min-height:44px !important;
    }
  }
}
.upa{
  @media (max-width:992px){
    .middle-content{
      margin-top: 0;
    }
  }
  .middle-content.edit-profile.user-right-container {
    position: relative;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    height: auto;
    .box{
      min-height: 470px;
      overflow-y: initial;
      padding-right: 0;
      .scroller{
        overflow-y: auto;
        height: 415px;
        padding-right: 30px;
      }
    }
  }
}
.patient-dashbaord-appointments {
  .profile-info {
    .box {
      max-height: 470px;
      overflow-y: auto;
      .user-info {
        &:last-child, &:only-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.w-400{
  max-width:400px;
}
.dashboard-calendar-card {
  .rbc-calendar {
    width: 100%;
    .rbc-event {
      cursor: default !important;
    }
  }
  .dashboard-calendar-wrapper {
    padding: 23px 36px 15px 35px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    .rbc-calendar {
      width: 100% !important;
      height: 415px !important;
      padding: 0;
      box-shadow: none;
      border-radius: 0;
    }
    .calendar-setting {
      padding: 15px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      span {
        @include font-mixin($font-500, 14px, #777, 18px);
      }
      a {
        @include font-mixin($font-500, 14px, #fb7304, normal);
        text-decoration: underline;
      }
    }
  }
}
.doctor-profile-view-wrapper {
  .action-status {
    display: inline-flex;
    align-items: flex-start;
    margin-left: auto;
    h4 {
      margin: 13px 10px 0 0;
    }
    .btn-white-outline {
      width: 40px;
      height: 40px;
      padding: 7px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.recent-activity-box {
  .no-data-section {
    box-shadow: none;
    h2 {
      color: #3b3b3b;
    }
  }
}
.zoom{
  .profile-info .box{
    min-height:auto;
    padding-bottom: 0px;
  }
  .card-row{
    padding-bottom: 0;
    margin-bottom: 20px;
  }
  .user-info{
    align-items: center;
    padding-bottom: 20px;
    .head{
      margin-bottom: 0;
    }
  }
}

.zoom-container{
  border-radius: 0;
  overflow: hidden;
  background-color: transparent;
  display:flex;
  position: absolute;
  top: 100%;
  right: 15px;
  width: calc(100% - 30px);
  flex-wrap: wrap;
  align-content: flex-start;
  min-height: 700px;
  @media(max-width: 1024px){
    position: relative;
    min-height: 10px;
    top: 0;
    right: 0;
    width: 100%;
    margin-bottom: 50px;
  }
  .zoom-ui{
    width: 635px;
    background: #242424;
    height: 500px;
    @media(max-width: 1024px){
      display: none;
    }
  }
  .content-section{
    width: calc(100% - 635px);
    height: 500px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    background: #fff;
    margin-left: 0;
    border-radius: 8px;
    .tab-section {
      height: 100%;
      .tab-content {
          height: calc(100% - 61px);
          .tab-pane {
            height: 100%;
          }
          .divTable {
            &.history {
              max-height: 300px;
              overflow-y: auto;
            }
          }
          .label-float{
            background: #f5f5f5;
            box-shadow: none;
          }
          textarea {
            resize: none;
            &::placeholder{
              font-size:14px
            }
          }
          .dropdown-menu {
            button {
              margin-bottom: 5px;
              padding: 5px 15px;
            }
          }
      }
      .nav-tabs {
        background: #fb7304;
        margin: 0;
        padding: 0 30px;
        border-top-right-radius: 8px;
      }
      .nav-item {
        .nav-link {
          margin-right: 50px;
          background: transparent;
          color: #f5d6bb;
          padding: 20px 0;
          &:after {
            display: none;
          }
          &.active {
            color: #fff;
            font-weight: 500;
          }
        }
      }
      @media(max-width: 1200px){
        .nav-item {
          &.doctorDocumentTabHead {
            .nav-link {
              margin-right: 7px;
              span {
                padding-right: 0;
              }
            }
          }
          &.patientDocumentTabHead {
            .nav-link {
              margin-right: 20px;
            }
          }
        }
      }
    }
    @media(max-width: 1024px){
      width: 100%;
      height: auto;
      order: 2;
      .tab-section {
        .tab-content {
          height: auto;
        }
        .nav-tabs {
          position: relative;
          top: 0;
          height: auto;
          display: block;
          border-radius: 0 !important;
          .nav-link {
            margin-right: 10px;
          }
        }
        .document-tab-body {
          .d-flex-table {
            height: auto;
            .table-body {
              height: 300px;
            }
          }
        }
      }
      .d-flex-table {
        .table-body {
          .divRow {
            box-shadow: none;
            border-bottom: 1px solid  #dbdbdb;
            border-radius: 0;
          }
        }
      }
    }
  }
  .session-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
    a {
      @include font-mixin($font-500, 14px, #fb7304, normal);
      text-decoration: underline;
      &.zoom-link-mobile {
        display: none;
        width: 300px;
        height: 40px;
        background: #fb7304;
        color: #fff;
        text-decoration: none;
        line-height: 40px;
        text-align: center;
        border-radius: 4px;
        font-size: 15px;
      }
    }
    @media(max-width: 1024px){
      width: 100%;
      height: auto;
      order: 1;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 30px;
      a {
        margin: 10px;
        &.zoom-link-mobile {
          display: inline-block;
        }
        &.zoom-link-desktop {
          display: none;
        }
      }
      .btn {
        width: 300px;
      }
    }
  }
}

.tab-section{
  .label-float .form-control{
    background-color: #f5f5f5;
    margin-bottom: 0;
  }
  textarea.form-control:focus + label, textarea.form-control:not(:placeholder-shown) + label{
    background-color: #f5f5f5;
  }
  .nav-tabs {
    margin: 0 30px;
    .nav-link{
      @include font-mixin($font-400, 14px, #777, normal);
      padding: 20px 10px;
      margin-right: 65px;
      border: 0;
      margin-bottom: 0 !important;
      &:after {
        width: 100%;
        height: 1px;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: transparent;
      }
      &.active, &:hover{
        border: 0;
        color: #3b3b3b;
        &:after {
          background: #3b3b3b;
        }
      }
    }
  }
  .body{
    padding: 30px;
    h4{
      @include font-mixin($font-500, 13px, #3b3b3b, normal);
    }
    .position-relative{
      span{
        position: absolute;
        right: 0;
        top: -3px;
        font-size:20px;
      }
    }
    .last-updated-box{
      position: relative;
      display: block;
      clear: both;
      .last-update{
        @include font-mixin($font-400, 12px, #777, normal);
        display:block;
        margin-top: 5px;
        position:absolute;
        left:0;
        top: -20px;
      }
    }

    .dropdown-btn{
      button{
        width: 100%;
        margin-bottom: 10px;
        padding: 12px 15px;
      }
    }
    .card{
      border: 0;
      .card-header {
        padding: 0;
        margin-bottom: 0;
        background-color: transparent;
        border-bottom: 0;
      }
    }
  }
}
.action-button{
  display:flex;
  align-items: center;
  justify-content:space-between;
  margin-top: 30px;
  .btn-sec {
    display:flex;
  }
}
.switch-text{
  display: flex;
  align-items: center;
  span{
    @include font-mixin($font-400, 14px, #777, normal);
    margin-right: 4px;
  }
}
.progress{
  margin-bottom: 0;
}
.upload-doc{
  text-align: center;
  min-height: 110px;
  padding: 15px;
  border: dashed 2px #eee;
  border-radius: 8px;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  .inner{
    position: relative;
    strong{
      @include font-mixin($font-500, 16px, #3b3b3b, normal);
      margin-bottom: 15px;
      display: block;
    }
    .allowed-size-text{
      @include font-mixin($font-400, 12px, #777, normal);
      display:block;
      margin-top: 5px;
    }
  }
  .progress {
    position: absolute;
    top: 42px;
    width: 160px;
    height: 7px;
    margin-left: calc(50% - 80px);
    .progress-bar {
      background-color: #fc7f2b!important;
      font-size: 8px;
    }
    @media(max-width: 1024px){
      margin-left: calc(50% - 80px);
    }
  }
  .error-text-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    bottom: -15px;
    font-size: 11px;
    color: red;
    text-align: center;
    width: 100%;
    p {
      margin: 0;
      &:first-child {
        position: relative;
        order: 2;
        margin-right: 5px;
      }
      &:last-child {
        margin-right: 5px;
        position: relative;
        order: 1;
      }
    }
  }
}
.payment-section{
  h3{
    margin-top: 40px;
    @include font-mixin($font-600, 18px, #3b3b3b, normal);
  }
  .go-to-stripe{
    background: #FFFFFF;
    min-height: auto;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    margin-top: 20px;
    display:flex;
    align-items: center;
    justify-content:space-between;
    p{
      @include font-mixin($font-400, 14px, #666, normal);
      margin: 0;
    }
  }
  .box{
    background: #FFFFFF;
    min-height: auto;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    margin-top: 20px;
    }
    .info{
      h4{
        @include font-mixin($font-400, 16px, #3b3b3b, normal);
      }
      p{
        @include font-mixin($font-400, 13px, #666, normal, 22px);
        margin: 5px 0 0;
        strong{
          @include font-mixin($font-700, 13px, #3b3b3b, normal);
        }
      }
      span{
        @include font-mixin($font-400, 13px, #939393, normal);
        display:block;
      }
    }
    .not-a-member{
      display:flex;
      align-items:flex-start;
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 20px;
      margin-bottom: 20px;
      img{
        margin-right: 20px;
      }

    }
    .monthly-price{
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 20px;
      margin-bottom: 20px;
      .info{
        border-top: 1px solid #d8d8d8;
        padding-top: 20px;
        margin-top: 20px;
        p{
          margin-top: 0;
        }
        span {
          font-style: italic;
          @include font-mixin($font-600, 13px, rgb(97, 96, 96), normal);
        }
      }
      .price{
        @include font-mixin($font-700, 40px, $theme-orange-color, normal);
        position:relative;
        padding-left: 10px;
        line-height:normal;
        strong{
          @include font-mixin($font-700, 16px, $theme-orange-color, normal);
          position:absolute;
          left: 0px;
          top:5px;
        }
        span{
          @include font-mixin($font-400, 13px, #777, normal);
        }
        p {
          margin: 0 7px 0 0;
          @include font-mixin($font-700, 18px, #777, normal);
          display: inline-flex;
          position: relative;
          padding: 0 5px;
          &::before {
            width: 100%;
            height: 1px;
            background: #777;
            top: 10px;
            left: 0;
            content: "";
            position: absolute;
          }
        }
      }
      ul{
        text-decoration: none;
        padding: 0;
        margin: 0;
        display:flex;
        align-items: center;
        margin-top: 15px;
        li{
          display:flex;
          align-items: center;
          margin-right: 30px;
          span{
            color: #666;
            font-size: 18px;
          }
          p{
            @include font-mixin($font-500, 14px, #666, normal);
            margin: 0 0 0 10px;
          }
        }
        @media(max-width:640px){
          flex-wrap: wrap;
          li {
            width: 100%;
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }


    }
    .btn-sec{
      a{
        @include font-mixin($font-500, 13px, $theme-orange-color, normal);
        font-style: italic;
        text-decoration:underline;
      }
    }
    .membership-info-footer {
      display: flex;
      justify-content: space-between;
      p {
        @include font-mixin($font-500, 13px, #666, normal);
        font-style: italic;
        margin: 10px 0 0 0;
        width: calc(100% - 194px);
      }
      .membership-info-tooltip {
        width: 30px;
        span {
          margin-top: 10px;
          color: #c5c5c5;
        }
      }
      .btn-wrapper {
        display: inline-flex;
        flex-wrap: wrap;
        width: 165px;
        justify-content: center;
        a {
          margin: 5px 0 0 0;
        }
      }
      &.update-membership-footer {
        p {
          width: calc(100% - 275px);
        }
        .btn-wrapper {
          flex-wrap: nowrap;
          width: 245px;
          justify-content: flex-end;
          .btn {
            min-width: 115px;
            & + .btn {
              margin-left: 10px;
            }
          }
        }
      }
      @media(max-width:640px){
        flex-wrap: wrap;
        p {
          width: 100% !important;
          margin-bottom: 20px;
        }
        .btn-wrapper {
          margin-right: auto;
        }
      }
    }
}
.payment-transaction{
  .sorting-icon {
    display: flex;
    width: 10px;
    height: auto;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 8px;
    span {
        width: 100%;
        height: 7px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 0 !important;
        font-size: 12px !important;
        color: #777;
        &.active {
            color: #000;
            font-weight: 700;
        }
    }
  }
  h3{
    margin-top: 40px;
    @include font-mixin($font-600, 18px, #3b3b3b, normal);
  }
  .card-sec{
    display:flex;
    margin: 0 -10px;
    .box{
      width: 33.333%;
      padding: 20px 10px 20px 20px;
      margin: 0 10px;
      border-radius: 8px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
      background-color:#fff;
      display: flex;
      align-items: center;
      .info{
        h4{
          @include font-mixin($font-700, 20px, #4a4a4a, normal);
        }
        strong{
          @include font-mixin($font-500, 14px, #4a4a4a, normal);
        }
        p{
          @include font-mixin($font-400, 12px, #666, normal);
          margin: 0;
        }
      }
      .icon-bg{
        margin-right: 20px;
        display:flex;
        align-items: center;
        justify-content:center;
        width: 44px;
        background-color:#fef1e5;
        border-radius:50%;
        height: 44px;
        span{
          color: $theme-orange-color;
          &.material-icons-sharp {
            font-size: 20px;
          }
          &.patient-served-icon {
            background: url('./../images/patient-served.svg') no-repeat center;
            width: 22px;
            height: 22px;
            background-size: 19px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .data-not-found{
    min-height:377px;
    background: #FFFFFF;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    margin-top: 20px;
    display:flex;
    align-items: center;
    justify-content:space-between;
    .content{
      text-align: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .icon-bg{
      height: 140px;
      width: 140px;
      border-radius: 50%;
      background-color: #f4f4f4;
      display:flex;
      align-items:center;
      justify-content:space-between;
      span{
        color:#999999;
        font-size: 50px;
        margin: 0 auto;
      }
    }
    h2{
      @include font-mixin($font-500, 16px, #3b3b3b, normal);
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
  .filter-row{
    margin: 20px 0;
    display: flex;
    justify-content:space-between;
    align-items:center;
    padding-left: 20px;
    .month{
      display: flex;
      align-items:center;
      span{
        display: inline-block;
        text-transform: uppercase;
        @include font-mixin($font-400, 13px, #3b3b3b, normal);
      }
      .label--checkbox {
        position: relative;
        width: 20px;
        height: 20px;
        label {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 18px;
            margin: 0;
            &:before {
                top: 4px;
                left: 3px;
            }
            &:after {
                top: 0;
                left: 0;
            }
        }
      }
    }
    .row-action-status {
      .btn-white-outline {
        padding: 6px 10px;
        order: 2;
        display: inline-flex;
        align-items: center;
        background: #fb7304;
        span {
          font-size: 20px;
          color: #fff;
        }
        &:hover {
          span {
            color: #fff;
          }
        }
        &:disabled {
          background: #ccc;
          opacity: 1;
          span {
            color: #fff;
          }
          &:hover {
            span {
              color: #fff !important;
            }
          }
        }
      }
      .filter-dropdown {
        order: 3;
      }
    }
    @media(max-width:640px){
      flex-wrap: wrap;
      .action-status {
        margin-top: 15px;
        .applied-filter-info {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
  .payment-list-section{
    margin-top: 25px;
    .table-section .box{
      box-shadow: none;
      padding: 0;
    }
    .total-refunds{
      border-radius: 8px;
      background-color:#77d9d3;
      padding: 14px;
      margin-top: 15px;
      ul{
        padding: 0;
        margin: 0;
        list-style: none;
        display:flex;
        align-items: center;
        justify-content:center;
        li{
          margin: 0;
          padding: 0 20px;
          @include font-mixin($font-400, 14px, #30514e, normal);
          strong{
            @include font-mixin($font-700, 16px, #30514e, normal);
          }
          &:not(:last-child){
            border-right: 1px solid #62b7b2;
          }
        }
      }
    }
    .list{
      border-radius: 8px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
      background-color:#fff;
      padding: 20px;
      margin-bottom: 15px;
      .month-row{
        display: flex;
        justify-content:space-between;
        align-items:center;
        .visit-count{
          display: inline-block;
          @include font-mixin($font-400, 14px, #3b3b3b, normal);
          margin-right: 15px;
        }
        .month{
          display: flex;
          align-items:center;
          .label--checkbox {
            position: relative;
            width: 20px;
            height: 20px;
            label {
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 18px;
                margin: 0;
                &:before {
                    top: 4px;
                    left: 3px;
                }
                &:after {
                    top: 0;
                    left: 0;
                }
            }
          }
        }
        span {
          font-size: 14px;
        }
        .visits{
          display: flex;
          align-items:center;
          .btn-white-outline {
            padding: 0;
            height: 40px;
            width: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            span {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .row-action-status {
    .btn-white-outline {
      padding: 6px 10px;
      span {
        font-size: 20px;
      }
    }
  }
  @media(max-width:992px){
    margin-top: 40px;

  }
  @media(max-width:640px){
    .card-sec {
      flex-wrap: wrap;
      margin: 0;
      .box {
        width: 100%;
        margin: 10px 0;
      }
    }
  }
}
.patient-payment-history{
  .sorting-icon {
    display: flex;
    width: 10px;
    height: auto;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 8px;
    span {
        width: 100%;
        height: 7px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 0 !important;
        font-size: 12px !important;
        color: #777;
        &.active {
            color: #000;
            font-weight: 700;
        }
    }
}
  .img-name{
    display: flex;
    align-items: center;
    span {
      word-break: break-word;
    }
  }
  .profile-img{
    width: 40px;
    height: 40px;
    margin-right: 15px;
    border-radius: 50%;
    flex: none;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .table-list{
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(109, 109, 109, 0.05);
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px
  }
  .data-not-found{
    min-height:377px;
    background: #FFFFFF;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(109, 109, 109, 0.05);
    margin-top: 20px;
    display:flex;
    align-items: center;
    justify-content:space-between;
    .content{
      text-align: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .icon-bg{
      height: 140px;
      width: 140px;
      border-radius: 50%;
      background-color: #f4f4f4;
      display:flex;
      align-items:center;
      justify-content:space-between;
      span{
        color:#999999;
        font-size: 50px;
        margin: 0 auto;
      }
    }
    h2{
      @include font-mixin($font-500, 16px, #3b3b3b, normal);
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
}
.patient-notes-row {
  h2 {
    display: flex;
    width: 100%;
    .btn {
      background: none;
      border: 0;
      min-height: 1px;
      line-height: normal;
      min-width: 20px;
      padding: 0;
      margin-left: auto;
    }
  }
}
.table-checkbox-position{
    top: -20px;
    position: relative;
    left: 20px;
}
