/***************
   Mixins
***************/


/* vendor-prefixes */

@mixin vendor-prefixes($property, $value) {
  #{$property}: $value;
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
}


/* font-mixin */

@mixin font-mixin ($name:false, $size: false, $colour: false, $weight: false, $lh: false, $ls: false) {
  @if $name {
    font-family: $name;
  }
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}


/* font-mixin */


/* placeholder */

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  /* Firefox 18- */
  &::-moz-placeholder {
    @content;
  }
  /* Firefox 19+ */
  &:-ms-input-placeholder {
    @content;
  }
}


/* 12 grid custom mixins */


/*
 * Generates classes for a grid of columns, prefixed by size.
 * The result will be .col-[size]-[col]-[total-columns] E.g. .col-xs-6-12
 *
 * @param {string}	$size			The name of the size target (e.g. xs, sm, md, lg, xl, etc.)
 * @param {int}		$grid-columns	Amount of columns
 */

@mixin grid-generator($size, $grid-columns) {
  @for $i from 1 through $grid-columns {
    .col-#{$size}-#{$i}-#{$grid-columns} {
      width: percentage($i / $grid-columns);
      float: left;
    }
  }
}


/*
 * A mixin targeting a specific device width and larger.
 * These are the available targets: 'xs', 'sm', 'md', 'lg', 'xl'
 *
 * @param {string} $media The target media
 */

@mixin respond-from($media) {
  @if $media==xs {
    @media (min-width: 0px) {
      @content;
    }
  }
  @else if $media==sm {
    @media (min-width: 767px) {
      @content;
    }
  }
  @else if $media==md {
    @media (min-width: 992px) {
      @content;
    }
  }
  @else if $media==lg {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @else if $media==xl {
    @media (min-width: 1800px) {
      @content;
    }
  }
}

@include grid-generator(xs, 12);
@include respond-from(sm) {
  @include grid-generator(sm, 12);
}

@include respond-from(md) {
  @include grid-generator(md, 12);
}

@include respond-from(lg) {
  @include grid-generator(lg, 12);
}

@include respond-from(xl) {
  @include grid-generator(xl, 10);
  @include grid-generator(xl, 12);
}


/* 12 grid custom mixins */


/*margin*/

$spaceamounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone
@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}


/*margin*/


/* clearfix */

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}


/* clearfix */


/* border */

@mixin border($direction, $width, $style) {
  border-#{$direction}: $width $style;
}


/* column mixin */
$totalColumns : 50;

$colWidths: ();
@for $i from 1 through $totalColumns {
  @for $j from 1 through $i {
    $w: ($j);
    @if not index($colWidths, $w ) {
      .table-column-#{$i} {
        width: $w * 1%;
      }
      $colWidths: append($colWidths, $w, comma)
    }
  }
}
