/***************
Buttons
***************/
.btn{
  &.btn-primary{
    min-width: 160px;
    min-height: 44px;
    text-transform: uppercase;
    border-radius: 8px !important;
    @include font-mixin($font-600, 1.4rem, #fff, normal);
    background:$theme-orange-color;
    border: 1px solid $theme-orange-color;
    &:hover, &:focus, &:active {
      background:$theme-orange-color2;
      border-color: $theme-orange-color;
      outline: 0 !important;
    }
    &.disabled, &:disabled{
      background:#999999;
      opacity: 1;
      border: 1px solid #999999;
    }
    &.green-btn{
      background-color: $theme-green-color;
      border: 0;
    }
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
      border-color: transparent;
      outline: 0;
    }
  }
  &.btn-secondary{
    min-width: 160px;
    text-transform:uppercase;
    min-height: 44px;
    border-radius: 8px !important;
    background:transparent;
    @include font-mixin($font-600, 1.4rem, $theme-orange-color, normal);
    border:1px solid $theme-orange-color;
    color: $theme-orange-color;
    &:active, &:hover, &:active {
      background: transparent !important;
      border-color: $theme-orange-color2 !important;
      color:$theme-orange-color !important;
    }
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
      border-color: transparent;
      outline: 0;
    }
  }
  &.small {
    min-height: 34px;
  }
  &.w-120{
    min-width: 120px;
  }
}
.dropdown-btn{
  .btn{
    border: solid 1px rgba(219, 219, 219, 0.8) !important;
    min-height: 30px !important;
    font-size: 12px !important;
    color: #777 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    text-transform: capitalize;
    .material-icons {
      font-family: 'Material Icons', Arial, sans-serif !important;
      color: #777 !important;
    }
    &:hover{
      .material-icons {
        color: $theme-orange-color !important;
      }
    }
  }
  .dropdown-menu{
    border-radius: 5px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    border: 0;
    width: 100%;
    min-width: max-content;
    .dropdown-item{
      @include font-mixin($font-400, 1.3rem, #666, normal);
      padding: 6px 15px;
      &:hover, &:active{
        background-color: #f9f3e7;
      }
    }
  }
}
