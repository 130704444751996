/*modal*/
.modal.show {
	opacity: 1;
}
.modal-dialog{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) !important;
}
/*profile edit modal*/
.modal.left .modal-dialog,
	.modal.right .modal-dialog {
    max-width:100%;
		position: fixed;
		margin: auto;
		width: 50%;
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
				transform: translate3d(0%, 0, 0);
				@media(max-width: 992px) {
					width: 100%;
				}
	}

	.modal.left .modal-body,
	.modal.right .modal-body {
		padding: 30px !important;
		max-width: 585px;
	width: 100%;
		@media(max-width: 992px) {
			max-width: 100%;
			.form-step {
				margin: 0;
				max-width: 100%;
			}
		}
	}

/*Left*/
	.modal.left.fade .modal-dialog{
		left: -50%;
		-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, left 0.3s ease-out;
		        transition: opacity 0.3s linear, left 0.3s ease-out;
	}

	.modal.left.fade.show .modal-dialog{
		left: 0;
	}

/*Right*/
	.modal.right.fade .modal-dialog {
		right: -50%;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}

	.modal.right.fade.show .modal-dialog {
		right: 0;
	}

.modal.left,
.modal.right {
	.modal-content {
		border-radius: 0;
		border: none;
		overflow-y: auto;
		margin-top: 70px;
    height: calc(100% - 70px) !important;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #fafaf2;
		transform: none !important;
    right: -50%;
    transition: opacity 0.3s linear, right 0.3s ease-out;
    left: initial;
	top: initial;
		@media(max-width: 992px) {
			right: 0%;
		}
	}

	.modal-header {
		border: 0;
		background-color: transparent;
    padding: 30px;
		max-width: 585px;
	width: 100%;
	@media(max-width: 992px) {
		max-width: 100%;
	}
    h5{
      @include font-mixin($font-600, 1.8rem, #4a4a4a, normal);
      display: flex;
      align-items:center;
      justify-content: space-between;
      width: 100%;
    }
	}
	.modal-footer{
    padding: 30px;
		max-width: 585px;
	width: 100%;
		@media(max-width: 992px) {
			max-width: 100%;
		}
	}
}
/*profile edit modal*/
.modal-content{
	border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
}
/*logout modal*/
.modal-logout{
	.modal-dialog{
		max-width:380px;
		@media(max-width:767px){
			max-width:100%;
			width: 100%;
			margin: 0;
		}
	}
	.modal-content{
		padding: 50px 40px;
		@media(max-width:767px){
			padding: 20px;
			margin: 0 15px;
			width: auto;
		}
		h1{
			@include font-mixin($font-500, 1.6rem, #3b3b3b, normal, 20px);
			margin-bottom: 15px;
			text-align: center;
		}
		p{
			@include font-mixin($font-400, 1.3rem, #3b3b3b, normal, 20px);
			margin: 0;
			text-align: center;
		}
	}
	.modal-body{
		padding: 0 !important;
		margin-bottom: 30px;
	}
	.modal-footer{
		padding: 0;
		border: 0;
		display: flex;
    align-items: center;
    justify-content: center;
		button{
			margin: 0 5px;
			min-width :120px;
			@media(max-width:767px){
				margin-bottom: 15px !important;
			}
		}
	}
}
.appointment-confirmed{
	.modal-dialog{
		max-width:420px;
		@media(max-width:767px){
			max-width:100%;
			width: 100%;
			margin: 0;
		}
	}
	.modal-content{
		padding: 50px 40px;
		@media(max-width:767px){
			padding: 20px;
			margin: 0 15px;
			width: auto;
		}
		h1{
			@include font-mixin($font-500, 1.6rem, #3b3b3b, normal, 20px);
			margin:20px 0 !important;
			text-align: center;
		}
		p{
			@include font-mixin($font-400, 1.3rem, #3b3b3b, normal, 20px);
			margin: 0 auto 30px;
			max-width:185px;
			text-align: center;
		}
		.label--checkbox, .label--radio{
			box-shadow:none;
		}
		.form-check .label--checkbox{
			padding-left: 0 !important;
		}
	}
	.modal-body{
		padding: 0 !important;
		margin-bottom: 10px;
		.col-sm-6{
			padding: 0;
			flex: 0 0 100%;
    		max-width: 100%;
		}
		&.emal-sent .check-box {
			width: 75px;
			height: 75px;
		}
		label label{
			margin-bottom: 20px;
		}
		.profile-info{
			.box{
				padding: 20px 20px 20px 20px;
				border-radius: 8px;
				background-color: #f6f6f6;
				min-height:auto;
				margin-bottom: 20px;
				box-shadow: none;
				.head{
					margin-bottom: 0;
				}
				.user-info{
					align-items: center;
					padding-bottom: 0;
					h2{
						text-align:left;
						margin-top: 0 !important;
						margin-bottom: 4px;
						&:last-child{
							margin-bottom: 0;
						}
						span{
							@include font-mixin($font-500, 1.4rem, #3b3b3b, normal, 20px);
							margin-left: 4px;
						}
					}
				}
			}
		}
		.action-checkbox {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
		}
	}
	.modal-footer{
		padding: 0;
		border: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		button{
			margin: 0 5px;
			min-width :145px;
			@media(max-width:767px){
				margin-bottom: 15px;
			}
		}
	}
}

.subscription-plan {
	.modal-dialog{
		max-width:350px;
		@media(max-width:767px){
			max-width:100%;
			width: 100%;
			margin: 0;
		}
	}
	.modal-content{
		padding: 50px 0;
		@media(max-width:767px){
			padding: 20px;
			margin: 0 15px;
			width: auto;
		}
		h1{
			@include font-mixin($font-600, 1.6rem, #3b3b3b, normal, 20px);
			margin: 0 0 20px 0;
			text-align: center;
			padding: 0 40px;
		}
		p{
			@include font-mixin($font-400, 1.4rem, #777, normal, 20px);
			margin: 0 auto 20px;
			padding: 0 30px;
			text-align: center;
			@media(max-width:767px){
				padding: 0;
			}
		}
	}
	.modal-body{
		padding: 0 !important;
		.subscription-amount {
			text-align: center;
			padding: 0 40px 10px 40px;
			border-bottom: 1px solid #d8d8d8;
			small {
				font-size: 21px;
				color: #FB7304;
				position: relative;
				top: -24px;
				left: -8px;
			}
			span {
				@include font-mixin($font-700, 60px, #FB7304, normal, 60px);
				margin-left: -8px;
			}
			h4 {
				@include font-mixin($font-600, 14px, #777, normal, 20px);
				margin-top: 5px;
			}
			p {
				margin: 0;
				@include font-mixin($font-700, 18px, #777, normal);
				display: inline-block;
				position: relative;
				padding: 0 5px;
				&::before {
				  width: 100%;
				  height: 1px;
				  background: #777;
				  top:10px;
				  left: 0;
				  content: "";
				  position: absolute;
				}
			  }
		}
		.subscription-features {
			padding: 20px 15px;
			border-bottom: 1px solid #d8d8d8;
			p {
				padding: 0;
				text-align: left;
				display: flex;
				align-items: center;
				@include font-mixin($font-500, 14px, #777, normal, 20px);
				margin-bottom: 20px;
				&:last-child, &:only-child {
					margin-bottom: 0;
				}
				span {
					margin-right: 15px;
					font-size: 18px;
				}
			}
			@media(max-width:767px){
				padding: 20px 0px;
			}
		}
		.subscription-actions {
			padding: 20px 0 0 0;
			text-align: center;
			p {
				padding: 0 30px;
				font-size: 14px;
				color: #777;
				span {
					@include font-mixin($font-600, 14px, #3b3b3b, normal);
				}
				@media(max-width:767px){
					padding: 0;
				}
			}
			.btn {
				min-width: 100px;
				& + .btn {
					margin-left: 10px;
				}
				&.link-btn {
					line-height: 41px;
					padding: 0 14px;
				}
			}
			&.cancellation-subscription-modal-footer {
				flex-wrap: wrap;
				display: flex;
				justify-content: center;
				padding: 0 30px;
				.link-wrapper {
					display: block;
					text-emphasis: center;
					width: 100%;
					margin-top: 10px;
					a {
						width: auto;
						&:hover {
							color: #FB7304;
						}
					}
				}
			}
		}
	}
}
.subscription-confirmation{
	&.modal-dialog{
		width:350px;
		@media(max-width:767px){
			max-width:100%;
			width: 100%;
			margin: 0;
		}
	}
	.modal-content{
		padding: 40px 30px;
		@media(max-width:767px){
			padding: 20px;
			margin: 0 15px;
			width: auto;
		}
		h1{
			@include font-mixin($font-500, 1.6rem, #3b3b3b, normal, 20px);
			margin: 0 0 15px!important;
			text-align: center;
		}
		p{
			@include font-mixin($font-400, 1.3rem, #3b3b3b, normal, 20px);
			margin: 0 auto 30px;
			text-align: center;
		}
	}
	.modal-body{
		padding: 0 !important;
		min-height: 170px;
		.btn {
			padding: 0;
			line-height: 44px;
		}
		.subscription-confirmation-modal-footer, .card-update-success {
			a {
				&.theme-color {
					&:hover {
						color: #FB7304;
					}
				}
			}
		}
	}
}

.appointment-cancel{
	&.modal-dialog{
		width:330px;
		@media(max-width:767px){
			max-width:100%;
			width: 100%;
			margin: 0;
		}
	}
	.modal-content{
		padding: 50px 40px;
		@media(max-width:767px){
			padding: 20px;
			margin: 0 15px;
			width: auto;
		}
		h1{
			@include font-mixin($font-500, 1.6rem, #3b3b3b, normal, 20px);
			margin: 0 0 15px!important;
			text-align: center;
		}
		p{
			@include font-mixin($font-400, 1.3rem, #3b3b3b, normal, 20px);
			margin: 0 auto 30px;
			max-width:185px;
			text-align: center;
		}
		.label--checkbox, .label--radio{
			box-shadow:none;
		}
		.form-check .label--checkbox{
			padding-left: 0 !important;
		}
	}
	.modal-body{
		padding: 0 !important;
		margin-bottom: 10px;
		.col-sm-6{
			padding: 0;
			flex: 0 0 100%;
    		max-width: 100%;
		}
		&.emal-sent .check-box {
			width: 75px;
			height: 75px;
		}
		label label{
			margin-bottom: 20px;
		}
		.profile-info{
			.box{
				padding: 20px 20px 20px 20px;
				border-radius: 8px;
				background-color: #f6f6f6;
				min-height:auto;
				margin-bottom: 20px;
				box-shadow: none;
				.head{
					margin-bottom: 0;
				}
				.user-info{
					align-items: center;
					padding-bottom: 0;
					h2{
						text-align:left;
						margin-top: 0 !important;
						margin-bottom: 4px;
						&:last-child{
							margin-bottom: 0;
						}
						span{
							@include font-mixin($font-500, 1.4rem, #3b3b3b, normal, 20px);
							margin-left: 4px;
						}
					}
				}
			}
		}
		.action-checkbox {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
		}
	}
	.modal-footer{
		padding: 0;
		border: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		button{
			margin: 0 5px;
			min-width :145px;
			@media(max-width:767px){
				margin-bottom: 15px;
			}
		}
	}
}
.pending-appointment{
	.modal-dialog{
		max-width:830px;
		width: 100%;
		position: relative;
		top: auto;
		left: auto;
		transform: none !important;
		@media(max-width:767px){
			max-width:100%;
			width: 100%;
			margin: 0;
		}
	}
	.modal-content{
		padding: 50px 40px 25px 40px;
		@media(max-width:767px){
			padding: 20px;
			margin: 0 15px;
			width: auto;
		}
		h1{
			@include font-mixin($font-500, 1.8rem, #4a4a4a, normal, normal);
			margin:0 0 10px !important;
			text-align: left;
		}
		p{
			@include font-mixin($font-400, 1.3rem, #3b3b3b, normal, 20px);
			margin: 0 auto 30px;
			max-width:185px;
			text-align: center;
		}
		.label--checkbox, .label--radio{
			box-shadow:none;
		}
		.form-check .label--checkbox{
			padding-left: 0 !important;
		}
		.button-group{
			flex:1;
			width: 280px;
			justify-content: flex-end;
			display: flex;
			flex-wrap: wrap;
			@media(max-width:767px){
				justify-content: flex-start;
				margin-left: -5px;
				width: auto;
				margin-top: 15px;
			}
			button{
				margin: 5px;
				min-width: 115px;
				&.btn-secondary {
					&.disabled {
						color: #fff;
						background-color: #999999;
						border-color: #999999;
						opacity: 1;
						&:hover, &:focus, &:active {
							color: #fff !important;
							background: #999999 !important;
							opacity: 1;
    						border: 1px solid #999999 !important;
						}
					}
				}
				@media(max-width:640px){
					min-width: 100px;
				}
			}
		}
	}
	.modal-header {
		width: 30px;
		height: 30px;
		position: absolute;
		top: 41px;
		right: 30px;
		border: 0;
		padding: 0;
		z-index: 1;
		button {
			font-size: 30px;
    		font-weight: 100;
		}
		@media(max-width:640px){
			top: 14px;
			right: 16px;
		}
	}
	.modal-body{
		padding: 0 !important;
		margin-bottom: 10px;
		.col-sm-6{
			padding: 0;
			flex: 0 0 100%;
    		max-width: 100%;
		}
		&.emal-sent .check-box {
			width: 75px;
			height: 75px;
		}
		.personal-message-textarea {
			.label-float {
				box-shadow: none;
				.form-control{
					background:#f6f6f6;
					box-shadow: none;
					resize: none;
					margin-bottom: 0;
					height: 110px;
				}
				label {
					background: #f6f6f6;
				}
			}
		}
		label label{
			margin-bottom: 20px;
		}
		.profile-info{
			.box{
				padding: 20px 20px 30px 20px;
				border-bottom: 1px solid #eee;
				border-radius: 0;
				background-color: #fff;
				min-height:auto;
				margin-bottom: 30px;
				box-shadow: none;
				.head{
					margin-bottom: 0;
				}
				.user-info{
					align-items: center;
					padding-bottom: 0;
					h2{
						text-align:left;
						margin-top: 0 !important;
						margin-bottom: 4px;
						&:last-child{
							margin-bottom: 0;
						}
						span{
							@include font-mixin($font-500, 1.4rem, #3b3b3b, normal, 20px);
							margin-left: 4px;
						}
					}
				}
				@media(max-width:640px){
					.user-info {
						flex-wrap: wrap;
						.profile-img {
							margin: 0 0 20px 0;
						}
						.head {
							width: 100%;
							flex: none;
						}
					}
				}
			}
		}
	}
	.modal-footer{
		padding: 0;
		border: 0;
		display: flex;
		align-items: center;
		button{
			margin: 0 5px;
			min-width :135px;
			@media(max-width:767px){
				margin-bottom: 15px;
			}
		}
	}
}
.viewDocModal {
	.modal-dialog {
		&.viewDocModal-dialog {
			min-height: calc(100vh - 3.5rem);
			max-width: 600px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			left: 0;
			transform: none !important;
			margin: 1.75rem auto;
		}
	}
	.modal-header {
		border: 0;
		button {
			padding: 12px 20px 0 0;
			span {
				font-size: 30px;
				font-weight: 100;
			}
		}
	}
	.modal-body {
		padding: 0 40px 40px 40px !important;
		text-align: center;
		iframe {
			width: 100%;
			height: 550px;
		}
		img {
			max-width: 100%;
		}
	}
}

.paymentModal {
	.modal-dialog {
		&.paymentModal-dialog {
			min-height: calc(100vh - 3.5rem);
			max-width: 400px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			left: 0;
			transform: none !important;
			margin: 1.75rem auto;
		}
	}
	.modal-content {
		min-height: 213px;
		.spinner-component {
			&.full-page-spinner {
				height: 100px;
			}
		}
	}
	.modal-header {
		border: 0;
		padding: 20px 40px;
		.modal-title {
			@include font-mixin($font-600, 1.6rem, #3b3b3b, normal, 20px);
		}
		button {
			padding: 0 10px 0 0;
			span {
				font-size: 30px;
				font-weight: 100;
			}
		}
	}
	.modal-body {
		padding: 0 40px 40px 40px !important;
		text-align: center;
		iframe {
			width: 100%;
			height: 550px;
		}
		img {
			max-width: 100%;
		}
	}
}

.cropModal {
	.modal-dialog {
		&.modal-cropp-dialog {
			min-height: calc(100vh - 3.5rem);
			max-width: 600px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			left: 0;
			transform: none !important;
			margin: 1.75rem auto;
		}
	}
	.modal-header {
		border: 0;
		button {
			padding: 12px 20px 0 0;
			span {
				font-size: 30px;
				font-weight: 100;
			}
		}
	}
	.modal-body {
		padding: 0 40px 40px 40px !important;
		text-align: center;
		iframe {
			width: 100%;
			height: 550px;
		}
		img {
			max-width: 100%;
		}
		.red-text-color-2 {
			width: 100%;
			text-align: right;
			display: inline-block;
			margin-top: 8px;
			padding-right: 12px;
		}
		.action-btn {
			margin-top: 20px;
			display: flex;
			justify-content: flex-end;
			.btn {
				&.btn-secondary {
					padding: 0 15px;
					line-height: 44px;
					@media(max-width:992px){
						min-width: 110px;
					}
				}
				&.btn-primary {
					@media(max-width:992px){
						min-width: 110px;
					}
				}
			}
		}
		label {
			margin-bottom: 0;
			input {
				display: none;
			}
		}
	}
	.image-error-text {
		text-align: right;
		width: 100%;
		display: block;
		margin: 10px 0 0 0 !important;
		p {
			margin: 0;
		}
	}
}
.start-appointment {
	.modal-dialog {
		&.start-appointment-dialog {
			min-height: calc(100vh - 3.5rem);
			max-width: 355px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			left: 0;
			transform: none !important;
			margin: 1.75rem auto;
		}
	}
	.modal-body {
		padding: 50px 30px !important;
		text-align: center;
		h1{
			@include font-mixin($font-500, 1.6rem, #3b3b3b, normal, 20px);
			margin-bottom: 15px;
			text-align: center;
		}
		p{
			@include font-mixin($font-400, 1.3rem, #3b3b3b, normal, 22px);
			margin: 0;
			text-align: center;
			margin-bottom: 25px;
		}
		.btn {
			width: 100%;
			margin-bottom: 10px;
			& + .btn {
				margin-bottom: 0;
			}
			&.outline {
				background: #fff;
				color: #FB7304;
				&:focus, &:hover, &:active {
					background: #fff !important;
					color: #FB7304;
					box-shadow: none;
					border-color:#FB7304;
				}
			}
		}
	}
}

.edit-profile-modal {
	.modal-body {
		padding: 20px 30px 30px 30px !important;
		.step-page {
			.middle {
				padding-bottom: 0;
				min-height: 1px;
				.container {
					padding: 0;
				}
			}
		}
		.form-skip {
			display: none;
			pointer-events: none;
		}
		.spinner-component {
			right: -50%;
			width: 100%;
			height: calc(100% - 70px);
			position: fixed;
			left: auto;
			flex: none;
			top: 70px;
			background: rgba(255, 255, 255, 0.95);
			@media(max-width:992px){
				right: 0;
			}
		}
		.doctor-registration-middle-container {
			min-height: 100% !important;
		}
	}
	.modal-close-btn {
		padding: 7px;
	}
	&.modal-dialog {
		transition: opacity 0.6s linear, right 0.6s ease-out !important;
	}
}

.modal-body-msg{
	border-radius: 8px;
	background-color:#f5f5f5;
	padding: 10px;
	text-align: left;
	p{
	text-align: left !important ;
	font-size:12px;
	color:#777;
	font-family:$font-400;

	}
	textarea {
		background: transparent;
		border: none;
		outline: none;
		width: 100%;
		font-size: 14px;
		@media(max-width:992px){
			font-size: 16px;
		}
	}
}

.modal-body-btn{
	display: block;
	.btn{
		min-width: 100% !important;
		margin-bottom: 15px !important;
	}
}
.cancel-apponitment-modal{
	width: 40rem;
	top: 0%;
	height: 10rem;
	.modal-body{
        height: 20rem;
        margin-top: 15px;
		padding: 7px !important;
	}
	div {
		margin-top: 65px;
	      .btn  {
		min-width: 125px;
		margin: 2px;
           }
	  }
	  h5{
		  margin-top: 15px;
	  }
}
.plane-checkbox{
	.col-sm-6{
		padding: 0;
    flex: 0 0 100%;
    max-width: 100%;
	}
	.label--checkbox, .label--radio{
		box-shadow:none;
	}
	.form-check .label--checkbox{
		padding-left: 0 !important;
	}
}
div > div.fade.show {
  opacity: 1 !important;
}
.modal-backdrop.fade.show {
	opacity: 0.6 !important;
}
