/***************
  Forms
***************/
// @import "bourbon";

$baseFontSize: 10;
@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}


@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
$primaryColor: $theme-orange-color;
$secondaryColor: #777777;

@function rem($val) {
  @return #{$val / $baseFontSize}rem;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 14px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 4px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px #eee;
}
/* Change autocomplete styles in WebKit */



::-webkit-input-placeholder { /* Chrome */
  color: transparent;
  font-size:0;
}
input:-ms-input-placeholder { /* IE 10+ */
  color: transparent;
  font-size:0;
}
::-moz-placeholder { /* Firefox 19+ */
  color: transparent;
  opacity: 1;
  font-size:0;
}
input::-moz-placeholder { /* Firefox 4 - 18 */
  color: transparent;
  opacity: 0;
  font-size:0;
}
input[type=text]:focus {
  outline: 0 !important;
}
:focus {
  outline: none;
}
.search-form{
  position: relative;
  span{
    position: absolute;
    left:20px;
    top: 10px;
    color: #777777;
  }
  ::-webkit-input-placeholder { /* Chrome */
    color: #585858;
    font-size: 14px;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #585858;
    font-size: 14px;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #585858;
    opacity: 1;
    font-size:14px;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: #585858;
    font-size: 14px;
    opacity: 1;
  }
}
.search-checkbox{
  background:#fff;
  padding: 10px 20px 10px 55px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  @include font-mixin($font-400, 1.4rem, #392112, normal);
  min-height:44px;
  margin-bottom: 15px;
  border: 1px solid #fff;
  &:focus {
    border:1px solid $theme-orange-color;
  }

}
.label--checkbox,
.label--radio {
  position: relative;
  margin: 0;
  font-family: Arial, sans-serif;
  line-height: 135%;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  &.flat{
    background: transparent;
    box-shadow: none;
    border: 0;
    padding: 0;
    min-height: auto;
    display: inline;
  }
  label{
    margin-left: 15px;
    @include font-mixin($font-400, 1.4rem, #3b3b3b, normal);
    > span{
      @include font-mixin($font-400, 1.2rem, #666666, normal);
      display:block;
      margin-top: 5px;
    }
  }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}

.checkbox {
  position: relative;
  top: -1px;
  margin: 0 2px 0 0 ;
  visibility: hidden;
  cursor: pointer;
  + label {
    cursor: pointer;
    position: relative;
      &:before {
      @include transition(transform .4s cubic-bezier(.45,1.8,.5,.75));
      @include transform(rotate(-45deg) scale(0, 0));
      content: "";
      position: absolute;
      left: -25px;
      top: 4px;
      z-index: 1;
      width: rem(12);
      height: rem(6);
      border: 2px solid $primaryColor;
      border-top-style: none;
      border-right-style: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0px;
      left: -28px;
      width: 18px;
      height: 18px;
      background: #fff;
      border: 2px solid $secondaryColor;
      cursor: pointer;
    }
  }
  &:checked + label{
    color:#392112 !important;
    &:before {
      @include transform(rotate(-45deg) scale(1, 1));
      border-color: #fff;
    }
    &:after {
      background:$theme-orange-color;
      border: 2px solid $theme-orange-color;
    }
  }
}

.radio {
  position: relative;
  margin: 0 0 0 0 ;
  cursor: pointer;
  visibility: hidden;
  + label{
    position: relative;
    &:before{
      cursor: pointer;
      @include transition(transform .4s cubic-bezier(.45,1.8,.5,.75));
      @include transform(scale(0,0));
      content: "";
      position: absolute;
      top: 3px;
      left: -27px;
      z-index: 2;
      width: 12px;
      height: 12px;
      background: $primaryColor;
      border-radius: 50%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0px;
      left: -30px;
      width: 18px;
      height: 18px;
      background: #fff;
      z-index: 1;
      border: 2px solid $secondaryColor;
      border-radius: 50%;
    }
  }


  &:checked + label{
    color:#392112 !important;
    &:before {
     @include transform(scale(1,1));
    }
    &:after {
      border-color: $theme-orange-color;
    }
  }
}
.form-check{
  padding-left: 0;
  .label--checkbox{
    padding-left: 15px !important;
  }
}
.radio-flex{
  display: flex;
  align-items: center;
  margin: 0;
  label{
    @include font-mixin($font-400, 1.4rem, #392112, normal);
    margin-left: 15px;
    margin-bottom: 0;
  }
}
.input-group{
  display: inherit;
  position:relative;
  .error-text, .red-text-color2{
    position: absolute;
    top: 70px;
    left: 0;
    line-height:18px;
    color: $red;
    font-size: 12px;
    + .label-float{
      .form-control{
        border-color: $red;
      }
    }
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  height: 45px;
  opacity: 0;
}
.w-350{
  max-width: 350px;
  margin: 0 auto;
}
.doctor-appointment-cancel-confirmation, .patient-appointment-cancel-confirmation {
  max-width: 400px;
  width: 100%;
  .cancel-appointemnt-btn-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    .btn {
      max-width: 210px;
      min-width: 210px;
      &.outline {
        background: #fff;
        color: #FB7304;
        &:focus, &:hover, &:active {
          background: #fff !important;
          color: #FB7304;
          box-shadow: none;
          border-color:#FB7304;
        }
			}
    }
  }
}
/*floating label*/
.label-float{
  position: relative;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 20px;
  .down-arrow{
    position: absolute;
    right: 10px;
    top: 20px;
  }
}

.label-float .form-control{
  border: 1px solid #fff;
  outline: none;
  box-shadow: none;
  transition: all .3s ease-out;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -webkit-appearance:none;
  border-radius: 8px !important;
  -webkit-box-shadow: none;
  &.form-control{
    padding: 31.8px 15px 15px !important;
    height: auto;
    @include font-mixin($font-500, 1.4rem, #3b3b3b, normal, 18px);
    width: 100%;
    min-width: 100%;

    box-shadow: none;
    -webkit-box-shadow: none;
    position: static;
    float: none;
    &:disabled, &[readonly] {
      background-color: #fff;
    }
    &:focus {
      outline: 0 !important;
    }
  }

}

.label-float .form-control:focus{
  border: 1px solid $theme-orange-color;
}

.label-float .form-control::placeholder{
  color:transparent;
}

.label-float > label{
  text-align: left;
  pointer-events: none;
  position: absolute;
  @include font-mixin($font-400, 1.4rem, #585858, normal, normal);
  margin-bottom: 0;
  top: 0;
  left: 15px;
  margin-top: 25px;
  transition: all .3s ease-out;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
}

.label-float .form-control:required:invalid + label{
  color: red;
}
.label-float .form-control:focus:required:invalid{
  border-bottom: 2px solid red;
}
.label-float .form-control:required:invalid + label:before{
  content: '*';
}
.label-float .form-control:focus + label,
.label-float .form-control:not(:placeholder-shown) + label{
  font-size: 12px;
  padding-top: 15px;
  color: #585858;
  width: calc(100% - 30px);
  margin-top: 1px;
}
textarea.form-control:focus  + label, textarea.form-control:not(:placeholder-shown) + label{
  background:#fff;
  width: calc(100% - 30px);
  margin-top: 1px;
}
.form-group{
  margin-bottom: 15px;
}
.custom-file-link{
  position: relative;
  .red-text-color2 {
    p {
      margin: 0;
    }
  }
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    @include font-mixin($font-400, 1.2rem, $theme-orange-color, normal, normal);
    /* Style as you please, it will become the visible UI component. */
  }
  .exampleFile, input {
    opacity: 0;
    left:0;
    top: 0;
    position: absolute;
    z-index: -1;
  }
}
.tooltip {
  @media(max-width:1024px){
    display: none !important;
  }
}
//tooltip
 .tooltip-inner {
  background-color: #333333 !important;
  font-size: 12px !important;
  line-height:18px !important;
  color: #dbdbdb !important;
  padding: 10px !important;
  max-width:335px !important;
  font-family: $font-400 !important;
}

#tooltip-top > .tooltip-arrow {
  border-top: 10px solid #062e56;
}
.login {
  .label-float {
    input:-webkit-autofill {
      & + label {
        margin-top: 0px !important;
      }
    }
    .form-control {
      @media(max-width:640px){
        font-size: 16px;
      }
    }
  }
}
.react-datepicker-wrapper{
  position: relative;
  display: block !important;
  margin-bottom: 20px;
  .react-datepicker__input-container{
    width: 100%;
    input{
      border: 1px solid #fff;
      outline: none;
      box-shadow: none !important;
      transition: all .3s ease-out;
      -webkit-transition: all .3s ease-out;
      -moz-transition: all .3s ease-out;
      -webkit-appearance:none;
      border-radius: 8px;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      padding: 31.8px 40px 15px 15px !important;
      height: auto;
      @include font-mixin($font-500, 1.4rem, #3b3b3b, normal, 18px);
      width: 100%;
      min-width: 100%;
      background-color:hsl(0, 0%, 100%);
      background-image: url(../images/elements-icons-calender-icon.svg);
      background-position: right 10px center;
      background-repeat: no-repeat;
      cursor:pointer;
      &.react-datepicker-ignore-onclickoutside{
        border: 1px solid $theme-orange-color;
        &:hover{
          border-color: $theme-orange-color;
        }
      }
      &:hover, &:focus{
        border-color: #fff;
        outline: 0 !important;
      }
    }
  }

}
/*datepicker*/
.react-datepicker{
  @include font-mixin($font-400 !important, 1.4rem !important, #3b3b3b !important, normal, 18px !important) ;
  border-radius:8px !important;
  border-color:#d7d4d4 !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
}
.react-datepicker__header {
  text-align: center;
  background-color: #fafaf2 !important;
  border-bottom: 1px solid #d7d4d4 !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  font-family: $font-500 !important;
}
.react-datepicker__header__dropdown--scroll{
  display:flex;
  justify-content: space-around;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  width: 3.2rem !important;
  line-height: 3.2rem !important;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  left: 108px !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  background-color: $theme-orange-color !important;
  font-family: $font-600 !important;
  border-radius:50% !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color: $theme-orange-color !important;
  font-family: $font-600 !important;
  border-radius:50% !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-weight: normal !important;
  font-size: 16px !important;
  height: 50px !important;
  padding-top: 3px;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{
  border-radius:50% !important;
}
/*datepicker*/
.reactSelect{
  .react-select__placeholder{
    display:none;
  }
  .react-select__control{
    border: 1px solid #fff;
    outline: none;
    box-shadow: none !important;
    transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -webkit-appearance:none;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    padding: 22.8px 15px 12px !important;
    height: auto;
    @include font-mixin($font-500, 1.4rem, #3b3b3b, normal, normal);
    width: 100%;
    min-width: 100%;
    margin-bottom: 20px;
    &:hover{
      border-color: #fff;
    }
    &.react-select__control--is-focused{
      border-color: $theme-orange-color;
    }
    .react-select__indicator-separator{
      display:none;
    }
    .react-select__value-container{
      padding-left: 0;
      height: 30px;
      &.react-select__value-container--is-multi{
        height: auto;
      }
      &.react-select__value-container--has-value{
        .react-select__single-value{
          @include font-mixin($font-500, 1.4rem, #3b3b3b, normal, normal);
        }
      }
      .react-select__multi-value{
        border-radius: 15px;
        margin-right: 5px;
        background-color:rgba(119,119,119, 0.1);
        margin-top: 8px;
        .react-select__multi-value__label{
          @include font-mixin($font-500, 1.2rem, #3b3b3b, normal, normal);
        }
        .react-select__multi-value__remove{
          &:hover{
            background-color:transparent;
            color: #000;
            cursor: pointer;
          }
        }
      }
      > div{
        margin-left: 0;
        margin-top: 6px;
        margin-bottom: 0;
      }
    }
    .react-select__indicator {
      padding:0;
      color: #777;
      margin-top: -10px;
      &.react-select__clear-indicator {
        display:none;
      }
    }
  }
  .react-select__menu{
    z-index: 10;
    margin-top: 0;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    .react-select__option{
      @include font-mixin($font-400, 1.3rem, #666, normal, normal);
      padding: 10px 15px;
      &:active, &.react-select__option--is-focused, &.react-select__option--is-selected{
        background-color:#f9f3e7;
        color: #392112;
        cursor: pointer;
      }
      &.react-select__option--is-selected{
        background-color:#f9f3e7;
      }
      .checkboxselect {
        position: absolute;
        margin: 0 2px 0 0;
        visibility: hidden;
        cursor: pointer;
        left: 0;
        top: -10px;
        width: 100%;
        height: 37px;
        + label {
          cursor: pointer;
          position: relative;
            &:before {
            @include transition(transform .4s cubic-bezier(.45,1.8,.5,.75));
            @include transform(rotate(-45deg) scale(0, 0));
            content: "";
            position: absolute;
            right: 3px;
            top: 4px;
            left: inherit;
            z-index: 1;
            width: rem(12);
            height: rem(6);
            border: 2px solid $primaryColor;
            border-top-style: none;
            border-right-style: none;
          }
          &:after {
            content: "";
            position: absolute;
            top: 0px;
            right: 0;
            left: inherit;
            width: 18px;
            height: 18px;
            background: #fff;
            border: 2px solid $secondaryColor;
            cursor: pointer;
          }
        }
        &:checked + label{
          color:#392112 !important;
          &:before {
            @include transform(rotate(-45deg) scale(1, 1));
            border-color: #fff;
          }
          &:after {
            background:$theme-orange-color;
            border: 2px solid $theme-orange-color;
          }
        }
      }
    }
  }
  .react-select__input {
    input {
      outline: 0 !important;
      transition: none !important;
      &:focus {
        outline: 0 !important;
      }
    }
  }
}
.selectLabel{
  &.selected{
    margin-top: 1px;
    font-size:12px;
    padding-top: 15px;
  }

}
.passwordshowhide{
  width: 100%;
  position: relative;
  .material-icons{
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;
  }
}
.plane-radio{
  .multi-radio{
    display: flex;
    margin-top: 10px;
    align-items: center;
    > span{
      font-family: "Archivo Regular", Arial, Helvetica, sans-serif;
      font-size: 1.4rem;
      color: #666;
      font-weight: normal;
    }
    .label--radio{
      margin: 0 10px;
      box-shadow:none;
      label{
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }
}
.read-only-field {
  .input-group {
    .label-float {
      input {
        color: rgba(171, 170, 170, 0.8);
        cursor: not-allowed;
      }
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 17px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d8d8d8;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
