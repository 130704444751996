.find-doctor-wrapper {
    width: 100%;
    height: calc(100vh - 130px);
    background: #fafaf2;
    overflow: auto;
    padding: 32px 0;
    .no-data-section {
        min-height: calc(100vh - 250px);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        h2 {
            width: 100%;
        }
    }
    @media(min-width: 992px) {
        .d-flex-table {
            .table-head {
                .headRow {
                    .table-col {
                        &:last-child {
                            padding: 0;
                        }
                    }
                }
            }
            .table-body {
                .divRow {
                    .table-col {
                        &:last-child {
                            padding: 0;
                            .action-status {
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }
        }
    }
    @media(max-width: 992px) {
        padding: 90px 0 30px 0;
        height: auto;
    }
}
.d-flex-table {
    .table-head {
        padding: 0 30px;
        .headRow {
            height: 70px;
            .table-col {
                justify-content: flex-start;
                &.select-all-checkbox {
                    padding-right: 0;
                }
            }
        }
        .check-btn {
            margin: 0;
            display: inline-flex;
            align-items: center;
            label {
                width: 18px;
                height: 18px;
                margin: 0;
                &:after {
                    left: 0;
                }
                &::before {
                    left: 3px;
                }
            }
            input {
                display: none;
            }
        }
        .sorting-icon {
            display: flex;
            width: 10px;
            height: auto;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 8px;
            span {
                width: 100%;
                height: 7px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-left: 0 !important;
                font-size: 12px !important;
                color: #777;
                &.active {
                    color: #000;
                    font-weight: 700;
                }
            }
        }
    }
    .table-body {
        padding: 0 30px;
        border-radius: 8px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
        background: #fff;
        .divRow {
            cursor: pointer !important;
            height: 70px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(219, 219, 219, 0.8);
            &:last-child, &:only-child {
                border: 0;
            }
            &:hover {
                cursor: default;
                background: #fff;
            }
            .table-col {
                span {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .check-btn {
                    margin: 0;
                    display: inline-flex;
                    align-items: center;
                    label {
                        width: 18px;
                        height: 18px;
                        margin: 0;
                        &:after {
                            left: 0;
                        }
                        &::before {
                            left: 3px;
                        }
                    }
                    input {
                        display: none;
                    }
                }
                .action-status {
                    .btn-white-outline {
                        padding: 10px;
                        &.active, &:hover {
                            background: #fbe9dd;
                            color: #FB7304;
                            border-color: #fbe9dd;
                            span {
                                color: #FB7304 !important;
                            }
                        }
                    }
                }
            }
            &.active {
                background: #efefef;
                border-color: #FB7304;
                position: relative;
                &:before {
                    content: "";
                    width: 30px;
                    height: 100%;
                    background: #efefef;
                    position:absolute;
                    top: 0;
                    left: -30px;
                }
                &:after {
                    content: "";
                    width: 30px;
                    height: 100%;
                    background: #efefef;
                    position:absolute;
                    top: 0;
                    right: -30px;
                }
                @media (max-width: 992px){
                    border: 0;
                    &::after, &::before {
                        display: none;
                    }
                }
            }
        }
        @media (max-width: 992px){
            background: transparent;
            box-shadow: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            .divRow {
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
                background: #fff;
                height: auto;
            }
        }
    }
    @media (max-width: 992px){
        .pagination-wrapper {
            padding: 0 15px;
        }
    }
    &.doctor-payment-table {
        width: 100%;
        margin: 0;
        .pagination-wrapper {
            padding: 10px 0 0 0;
        }
        .table-head {
            padding: 0;
            .table-col {
                .label--checkbox {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    label {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        margin: 0;
                        &:before {
                            top: 4px;
                            left: 3px;
                        }
                        &:after {
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
        }
        .table-body {
            padding: 0;
            box-shadow: none;
            width: 100%;
            .divRow {
                cursor: default !important;
            }
            @media (max-width: 992px){
                justify-content: space-between;
                .divRow {
                    box-shadow: none !important;

                }
                .sub-accoridon{
                    border-bottom: 1px solid #f7f7f7;
                }
            }
        }
    }
}
.pagination-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    .pagination {
        li {
            margin: 0 2px;
            &.disabled {
                cursor: default;
            }
            &.active {
                a {
                    background: #FB7304;
                    color: #fff;
                }
            }
            a {
                height: 30px;
                min-width: 30px;
                padding: 0 5px;
                line-height: 30px;
                text-align: center;
                display: inline-block;
                color: #606060;
                border-radius: 2px;
                font-size: 14px;
                border: 0;
                &:hover, &:focus {
                    background: #FB7304;
                    color: #fff;
                    outline: 0 !important;
                }
                &.next-btn {
                    padding: 0 12px;
                    background: #f1f1f1;
                    color: #3b3b3b;
                    text-transform: capitalize;
                    &[aria-disabled="true"] {
                        background: none;
                        cursor: default;
                        &:hover {
                            background: transparent;
                            color: #606060;
                        }
                    }
                    &:after {
                        content: "";
                        position: relative;
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        margin-left: 7px;
                        top: 1px;
                        background: url('./../images/pagination-next.svg') no-repeat center;
                    }
                    &:hover {
                        background: #f1f1f1;
                        color: #3b3b3b;
                        text-decoration: none;
                    }
                    @media(max-width:640px){
                        padding: 0 4px;
                    }
                }
                &.prev-btn {
                    padding: 0 12px;
                    background: #f1f1f1;
                    color: #3b3b3b;
                    text-transform: capitalize;
                    &[aria-disabled="true"] {
                        background: none;
                        cursor: default;
                        &:hover {
                            background: transparent;
                            color: #606060;
                        }
                    }
                    &:before {
                        content: "";
                        position: relative;
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        margin-right: 7px;
                        top: 1px;
                        background: url('./../images/pagination-prev.svg') no-repeat center;
                    }
                    &:hover {
                        background: #f1f1f1;
                        color: #3b3b3b;
                        text-decoration: none;
                    }
                    @media(max-width:640px){
                        padding: 0 4px;
                    }
                }
                @media(max-width:640px){
                    height: 20px;
                    min-width: 20px;
                    padding: 0 5px;
                    line-height: 20px;
                    font-size: 11px;
                }
            }
        }
    }
    @media(max-width:640px){
        padding: 0 !important;
    }
}
.list-search {
    .search-checkbox {
        padding-right: 50px;
        &:focus{
            outline: none !important;
        }
    }
    span {
        &.close {
            left: auto;
            right: 20px;
            top: 12px;
            font-size: 22px;
            cursor: pointer;
            opacity: 1;
        }
    }
}
