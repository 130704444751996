/*footer*/
footer{
  margin-top: 100px;
  background: $theme-orange-color;
  min-height: 80px;
  position: fixed;
  width: 100%;
  bottom:0;
  left:0;
  z-index: 2;
  .footer-content {
    padding: 24px 0 35px;
    text-align: center;
    p{
      @include font-mixin($font-400, 1.2rem, #717171, normal, 18px);
      margin: 0;
    }
    ul{
      list-style: none;
      margin: 0 0 8px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      li{
        a{
          @include font-mixin($font-600, 1.4rem, #2e3d5f, normal, 21px);
          padding: 0 5px;
        }
      }
    }
  }
}
.contactUs{
  .form-step{
    max-width:730px;
  }
  h1{
    margin-top: 30px;
    margin-bottom: 40px;
    @include font-mixin($font-600, 1.8rem, #4a4a4a, normal);
  }
}


.accordion{
  .card{
    background:transparent;
    border-top: 1px solid #e3e2da;
    border-bottom:1px solid #e3e2da;
    padding: 0;
    border-left: 0;
    border-right: 0;
    &:first-child{
      border-top:0;
    }
    .card-header{
      display:flex;
      justify-content:space-between;
      background:transparent;
      border:0;
      padding:24px 0;
      cursor:pointer;
      strong{
        @include font-mixin($font-400, 1.4rem, #3b3b3b, normal);
      }
    }
    .card-body{
      background:transparent;
      border:0;
      padding:0;
      margin-bottom:24px;
      p{
        @include font-mixin($font-400, 1.4rem, #666, normal);
        padding:0;
        margin:0
      }
    }
  }
}
.faqs{
  .back-link{
    margin: 30px 0;
  }
  .edit-nav{
    ul{
      li {
        &.active, &:hover{
          .name{
            color: #3b3b3b;
          }
          border-color: #3b3b3b;
        }

      }
    }
  }
}

.terms-policy{
  .back-link{
    margin: 30px 0;
    justify-content: center;
    .title-text{
      display: block;
      @include font-mixin($font-400, 1.2rem, #4a4a4a, normal);
      line-height: 28px;
    }
  }
  .link-list{
    padding: 10px 0;
    margin-top: 10px;
    li{
      list-style: none;
      @include font-mixin($font-400, 1.4rem, #666666, normal);
      padding: 3px 15px;
      a{
        text-decoration: underline;
        @include font-mixin($font-400, 1.4rem, #666666, normal);
      }
    }
  }
  ul{
    padding: 10px 0 0;
    margin-top: 10px;
    list-style: none;
    margin-left: 20px;
    > li{
      @include font-mixin($font-400, 1.4rem, #4a4a4a, normal);
      ul{
        margin-left: 40px;
        li{
          list-style-type: lower-alpha;
          @include font-mixin($font-400, 1.4rem, #666666, normal);
          margin: 20px 0;
        }
      }
    }
  }
  strong{
    @include font-mixin($font-700, 1.4rem, #666, normal);
  }
  p{
    @include font-mixin($font-400, 1.4rem, #666, normal);
    margin-top: 20px;
    margin-bottom: 0;
  }
}
