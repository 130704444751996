/*text colors*/

.theme-text-color {
  color: $theme-black-color;
}
.theme-text-color2 {
  color:$theme-black-color;
}
.red-text-color {
  color: $red;
}
.green-text-color{
  color: $green;
}
.red-text-color2 {
  color: #fa4616 !important;
}
.theme-bg-color {
  color: $theme-black-color;
}
.theme-dark-blue{
  color: $theme-dark-blue;
}
.gray666{
  color:#666;
}
.black4a{
  color: #4a4a4a !important;
}
.black3b{
  color: #3b3b3b !important;
}
/*text colors*/
.font-w-normal{
  font-weight: normal;
}

.text-decoration{
  text-decoration:underline;
}
/*colors*/
.grey-text-color {
  color: $grey;
}
.icon-color{
  &-grey{
    color: $grey !important;
  }
  &-black{
    color: $black !important;
  }
  &-white{
    color: $white !important;
  }
  &-yellow{
    color: #f0c911 !important;
  }
  &-blue{
    color: #5291ff !important;
  }
  &-red{
    color: $red !important;
  }
  &-green{
    color: #45ad92 !important;
  }
}

.icon-bgcolor{
  &-grey{
    background: $grey !important;
  }
  &-black{
    background: $black !important;
  }
  &-white{
    background: $white !important;
  }
  &-yellow{
    background: #f0c911 !important;
  }
  &-blue{
    background: #5291ff !important;
  }
  &-red{
    background: $red !important;
  }
  &-green{
    background: #45ad92 !important;
  }
}

/* font-size */

.font {
  &-10 {
    @include font-mixin($size: 1rem);
  }
  &-12 {
    @include font-mixin($size: 1.2rem !important);
  }
  &-14 {
    @include font-mixin($size: 1.4rem !important);
  }
  &-16 {
    @include font-mixin($size: 1.6rem !important) ;
  }
  &-18 {
    @include font-mixin($size: 1.8rem !important);
  }
  &-20 {
    @include font-mixin($size: 2rem);
  }
  &-22 {
    @include font-mixin($size: 2.2rem);
  }
  &-24 {
    @include font-mixin($size: 2.4rem);
  }
  &-26 {
    @include font-mixin($size: 2.6rem);
  }
  &-28 {
    @include font-mixin($size: 2.8rem);
  }
  &-30 {
    @include font-mixin($size: 3rem);
  }
  &-32 {
    @include font-mixin($size: 3.2rem);
  }
  &-34 {
    @include font-mixin($size: 3.4rem);
  }
  &-36 {
    @include font-mixin($size: 3.6rem);
  }
  &-38 {
    @include font-mixin($size: 3.8rem);
  }
  &-40 {
    @include font-mixin($size: 4rem);
  }
  &-42 {
    @include font-mixin($size: 4.2rem);
  }
  &-80 {
    @include font-mixin($size: 8rem);
  }
}


/* font-size */


/* font-family */


.font-300 {
  @include font-mixin($font-300 !important);
}

.font-400 {
  @include font-mixin($font-400 !important);
}

.font-500 {
  @include font-mixin($font-500 !important);
}

.font-600 {
  @include font-mixin($font-600 !important);
}

.font-700 {
  @include font-mixin($font-700 !important);
}



/* font-family */


/*font lineheight*/

.lineheight {
  &-normal {
    line-height: normal;
  }
  &-1 {
    line-height: 10px !important;
  }
  &-16 {
    line-height: 16px;
  }
  &-18 {
    line-height: 18px;
  }
  &-20 {
    line-height: 20px;
  }
  &-22 {
    line-height: 22px;
  }
  &-24 {
    line-height: 24px;
  }
  &-26 {
    line-height: 26px;
  }
  &-28 {
    line-height: 28px;
  }
  &-30 {
    line-height: 30px;
  }
  &-32 {
    line-height: 32px;
  }
  &-34 {
    line-height: 34px;
  }
  &-36 {
    line-height: 36px;
  }
  &-38 {
    line-height: 38px;
  }
  &-40 {
    line-height: 40px;
  }
  &-42 {
    line-height: 42px;
  }
  &-75 {
    line-height: 75px;
  }
}


/*font lineheight*/
/*width*/
.w {
  &-100 {
    width: 100% !important;
  }
  &-95 {
    width: 95% !important;
  }
  &-90 {
    width: 90% !important;
  }
  &-85 {
    width: 85% !important;
  }
  &-80 {
    width: 80% !important;
  }
  &-75 {
    width: 75% !important;
  }
  &-70 {
    width: 70% !important;
  }
  &-65 {
    width: 65% !important;
  }
  &-60 {
    width: 60% !important;
  }
  &-55 {
    width: 55% !important;
  }
  &-50 {
    width: 50% !important;
  }
  &-45 {
    width: 45% !important;
  }
  &-40 {
    width: 40% !important;
  }
  &-35 {
    width: 35% !important;
  }
  &-30 {
    width: 30% !important;
  }
  &-25 {
    width: 25% !important;
  }
  &-20 {
    width: 20% !important;
  }
  &-15 {
    width: 15% !important;
  }
  &-10 {
    width: 10% !important;
  }
}

.h-100 {
  min-height: 100%;
}

.h-100vh {
  min-height: 100vh;
}
.mt--15{
  margin-top: -15px !important;
  margin-left: 4px !important;
}
.w-auto {
  width: auto !important;
}
.min-w-auto {
  min-width: auto !important;
}
.h-auto {
  height: auto !important;
}

ul {
  &.list-style-none {
    list-style: none;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  text-align: left;
}

.p-0 {
  padding: 0 !important;
}

.py {
  &-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  &-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  &-3 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  &-4 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  &-5 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  &-6 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

.px {
  &-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  &-1 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  &-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  &-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  &-4 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  &-5 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  &-6 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}


.m-auto {
  margin: auto !important;
}

.my-auto {
  margin: auto 0 !important;
}

.mx-auto {
  margin: 0 auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.my {
  &-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  &-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  &-3 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  &-4 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  &-5 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  &-6 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
}

.mx {
  &-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  &-1 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  &-2 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  &-3 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  &-4 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  &-5 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  &-6 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}


/*border-radius*/

.border-radius {
  &-3 {
    border-radius: 3px;
  }
  &-5 {
    border-radius: 5px;
  }
  &-10 {
    border-radius: 10px;
  }
  &-15 {
    border-radius: 15px;
  }
  &-20 {
    border-radius: 20px;
  }
  &-50 {
    border-radius: 50%;
  }
}


/* custom class */

.border-grey {
  border: 1px solid $grey;
}


.nounderline {
  &:hover {
    text-decoration: none;
  }
}

.cursor-default {
  cursor: default;
}

.cursor-na {
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.hover-color-none {
  &:hover {
    color: inherit;
  }
}

.hover-none {
  &:hover {
    text-decoration: none;
    cursor: default;
  }
}

.transparent-bg {
  background: transparent;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}


/*flex*/

.flex {
  &-0 {
    flex: 0 !important;
  }
  &-1 {
    flex: 1 !important;
  }
  &-2 {
    flex: 2 !important;
  }
  &-3 {
    flex: 3 !important;
  }
  &-4 {
    flex: 4 !important;
  }
}


/*flex*/


/*icon color*/

.icon-color-gray {
  color: $grey;
}


.grey666{
  color: #666;
}
/*icon color*/


/*text align*/

.text {
  &-left {
    text-align: left !important;
  }
  &-right {
    text-align: right !important;
  }
  &-center {
    text-align: center !important;
  }
}


/*text align*/



.link {
  &-16{
    @include font-mixin($font-700, 1.6rem, $theme-black-color, normal, 24px);
  }
  &-hover{
    &:hover{
      color: $grey;
    }
  }
}
/* box-shadow  */

.box-shadow {
  &-small {
    @include vendor-prefixes(box-shadow, 0 2px 10px 0 rgba(0, 0, 0, 0.1));
  }
  &-medium {
    @include vendor-prefixes(box-shadow, 0 2px 15px 0 rgba(0, 0, 0, 0.1));
  }
  &-large {
    @include vendor-prefixes(box-shadow, 0 2px 22px 0 rgba(0, 0, 0, 0.1));
  }
}


/* box-shadow  */





/*border*/
.border-0{
  border: 0 !important;
}
.border {
  &-top {
    @include border('top', 1px, solid !important);
  }
  &-right {
    @include border('right', 1px, solid !important);
  }
  &-bottom {
    @include border('bottom', 1px, solid !important);
  }
  &-left {
    @include border('left', 1px, solid !important);
  }
}

.border-color {
  &-default {
    border-color: $grey !important;
  }
  &-lightgrey {
    border-color: #eee !important;
  }
}


/*border*/






