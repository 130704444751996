.patient-completed-appointments {
    .title-text {
        display: flex;
        flex-wrap: wrap;
    }
    .user-detail {
        h3 {
            margin-top: 0 !important;
        }
    }
    .price-sec {
        margin: 0 !important;
        flex: none;
        display: flex;
        align-items: flex-start;
        width: 300px !important;
        max-width: 300px !important;
        margin-left: auto !important;
        .user-appointment-info {
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            label {
                width: 100%;
            }
            span {
                width: 100%;
            }
        }
    }
    .datetime {
        width: 100%;
    }
}
