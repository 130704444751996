#zmmtg-root {
  display: none;
  background-color: transparent !important;
  position: relative !important;
  height: 500px !important;
  width: 635px !important;
  top: 0;
  margin-left: calc((100% - 1140px) / 2);
  #wc-loading {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    .loading-main {
      top: 20px !important;
      .join-meeting {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
    }
    .loading-img {
      display: none;
    }
  }
  .root-inner {
    width: 100% !important;
    height: 100% !important;
    .meeting-app {
      height: 100% !important;
      width: 100% !important;
      & > div {
        height: 100% !important;
        width: 100% !important;
      }
      #wc-leave {
        width: 100% !important;
        height: 100% !important;
        & + .meeting-client {
          position: absolute !important;
          top: 0;
          left: 0;
        }
      }
      .meeting-client, .meeting-client-inner {
        position: relative !important;
        .join-dialog {
          width: 100% !important;
          height: 180px !important;
          .join-dialog__tabs {
            .zmu-tabs__tab-container {
              height: 35px !important;
              .zmu-tabs__tabs-list {
                .zmu-tabs__tab-bar {
                  .tab-bar-node {
                    height: 35px !important;
                    font-size: 13px !important;
                  }
                }
              }
            }
            .join-audio-by-voip__join-btn {
              margin-top: 0 !important;
              height: 35px !important;
              font-size: 12px;
            }
          }
        }
        #wc-container-left {
          .main-layout {
            display: block;
            height: 100% !important;
            position: relative;
            & > div {
              height: 100% !important;
            }
            .gallery-video-container__main-view {
              height: calc(100% - 60px) !important;
              display: block !important;
              .gallery-video-container__wrap {
                width: 100% !important;
                height: calc(100% - 44px)!important;
                display: flex;
                align-items: center;
                justify-content: center;
                #main-video {
                  width: auto !important;
                  max-width: 100%;
                  height: auto !important;
                  max-height: 100%;
                }
              }
              .gallery-video-container__video-frame {
                width: 100% !important;
                height: calc(100% - 46px) !important;
                transform: none !important;
                top: 0;
                left: 0;
              }
            }
          }
        }
        #app-signal.pop-panel__button-wrap {
          display: none;
        }
        .footer {
          width: 100% !important;
          height: 45px !important;
          min-height: 45px !important;
          .footer__inner {
            justify-content: center !important;
          }
          .footer-button__button {
            margin: 0 10px;
            &[aria-label="Share Screen"] {
              display: none;
            }
          }
          .footer-button__button-label {
            margin: 0 5px !important;
            line-height: normal;
            font-size: 10px;
          }
          .more-button__button-label {
            font-size: 10px !important;
          }
          .join-audio-container {
            .join-audio-container__btn {
              padding: 0 !important;
              width: auto !important;
              margin: 0 10px;
              .zm-icon {
                transform: scale(0.6);
                margin: 0 !important;
              }
            }
            .audio-option-menu {
              button {
                &:after {
                  display: none;
                }
              }
            }
          }
          #securityOptionMenu {
            height: 44px;
            padding: 0;
            &:after {
              display: none;
            }
            .security-option-menu__button-icon {
              transform: scale(.6);
            }
            .security-option-menu__button-text {
              font-size: 12px;
              font-weight: 400;
              margin: 0;
            }
          }
          .footer__leave-btn-container {
            height: 44px !important;
          }
          .send-video-container {
            margin: 0 10px;
            .send-video-container__btn {
              padding: 0 !important;
              width: auto !important;
              .zm-icon{
                transform: scale(0.6);
                height: 28px !important;
                margin: 0 !important;
              }
            }
          }
          .footer-button__img-layer {
            height: 25px !important;
            .footer-button__participants-icon {
              transform: scale(0.7);
            }
          }
          .footer-button__chat-icon {
            transform: scale(.7);
          }
          .more-button {
            .more-button__button {
              height: 44px !important;
              padding: 0;
              margin: 0 10px;
              .more-button__img-layer {
                height: 25.5px !important;
                padding-top: 0 !important;
              }
            }
          }
        }
        #wc-content {
          background: #000;
        }
        .wr-content {
          margin-top: 0 !important;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          .wr-tile {
            max-width: 100% !important;
            width: 100%;
            font-size: 17px;
            margin: 0;
          }
          .wr-topic-layout {
            margin: 20px 0 0 0;
            width: 100%;
            max-width: 100% !important;
          }
        }
      }
    }
    #wc-loading {
      height: 100% !important;
      width: 100% !important;
    }
    .main-content {
      height: 100% !important;
      width: 100% !important;
      background: #242424 !important;
      #content {
        height: 100%;
        width: 100%;
        .container-preview {
          height: 100%;
          width: 100%;
          .av-preview-container {
            height: calc(100% - 67px) !important;
            width: 100% !important;
            top: 0 !important;
          }
          .mini-layout-body {
            margin: 21px 0 0 0 !important;
            width: 100%;
          }
          .media-preview-container {
            margin: 0;
            width: 100%;
            .meeting-title, p {
              display: none;
            }
          }
        }
      }
    }
  }
  #wc-container-left {
    width: 100% !important;
  }
  #wc-container-right {
    height: 100% !important;
    width: 50% !important;
    position: absolute;
    top: 0;
    right: 0;
    & > div {
      height: 100%;
      & > div {
        height: calc(100% - 60px);
      }
      .participants-header__header {
        .btn {
          &:after {
            display: none;
          }
        }
        & + div {
          height: calc(100% - 40px);
        }
      }
      .participants-item__buttons {
        margin: 0 !important;
        display: none !important;
        .btn {
          font-size: 11px;
          padding: 5px 10px !important;
          min-width: 60px;
          height: 25px !important;
          min-height: 25px !important;
          line-height: normal !important;
          text-transform: none;
          border-radius: 2px !important;
          &:after {
            display: none;
          }
        }
      }
      .participants-section-container__participants-footer {
        height: 60px !important;
        .btn {
          border: 1px solid #808080 !important;
          padding: 4px 13px !important;
          border-radius: 4px !important;
          font-size: 12px !important;
          font-weight: 600 !important;
          &::after {
            display: none;
          }
        }
      }
    }
    .chat-container {
      height: 100% !important;
      .chat-header__header {
        height: 44px;
        .btn {
          &:after {
            display: none;
          }
        }
      }
      .chat-container__chat-list {
        height: calc(100% - 158px) !important;
        .chat-content__chat-scrollbar {
          height: 100%;
          & > div {
            height: 100%;
          }
          .chat-virtualized-list {
            height: 100% !important;
            width: 100% !important;
          }
        }
        & + div {
          height: auto;
        }
      }
    }
    .participant-scrollbar {
      height: 100% !important;
    }
  }
  .sharing-setting-dropdown-menu-container {
    .btn {
      &:after {
        display: none;
      }
    }
  }
  .video-avatar__avatar-name {
    max-width: 100% !important;
    font-size: 20px !important;
  }
  .join-audio-by-voip__upgrade-tip {
    margin-top: 15px !important;
    font-size: 9px !important;
  }
  .zm-btn.joinWindowBtn {
    background: #FB7304 !important;
    border: 1px solid #FB7304 !important;
    font-size: 16px !important;
    border-radius: 0 !important;
  }
  @media (max-width: 1024px) {
    display: none !important;
    margin-left: 0 !important;
    .root-inner {
      display: none !important;
    }
  }
}
#aria-notify-area {
  display: none;
}
.meeting-info-container__full-screen {
  display: none;
}
.sharer-controlbar-container__overlay {
  background: #e4863f !important;
  height: 70px;
}
.suspension-video-container__container {
  display: none !important;
}
.full-screen-icon {
  display: none !important;
}
.footer__leave-btn-container {
  display: none !important;
}
.end-meeting-btn {
  position: absolute;
  bottom: 137px;
  right: 0;
}
.participants-ul {
  .participants-item__buttons {
    display: none !important;
  }
}
