.page-not-found {
    width: 100%;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
        @include font-mixin($font-600, 8rem, #4a4a4a, normal);
    }
    h4 {
        @include font-mixin($font-600, 4rem, #4a4a4a, normal);
    }
    a {
        width: 140px;
        height: 50px;
        line-height: 50px;
        padding: 0 15px;
        border: 1px solid #E6752B;
        color: #E6752B;
        text-decoration: none;
        margin: 30px auto 0 auto;
        &:hover {
            text-decoration: none;
        }
    }
}