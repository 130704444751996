/*  Assets */

$assetPath: "/assets/images";

/*  Devices */


/*color-style*/
$theme-green-color:                             #77D9D3;
$theme-black-color:                             #21140b;
$theme-orange-color:                            #FB7304;
$theme-orange-color2:                            #fb7304;

$theme-dark-blue:                               #2E3D5F;
$white:                                         #FFFFFF;
$black:                                         #000000;
$dark-grey:                                     #767676;
$dark-grey-2:                                   #717171;
$grey:                                          #E5E5E5;
$light-grey:                                    #F6F7FB;
$red:                                           #dd200c;
$green:                                         #02c618;
$yellow:                                        #FFFF00;
$border:$grey;
$border-light: rgba(151, 151, 151, 0.2);

/*font-size*/

$font-size-x-small: 12px;
$font-size-small: 14px;
$font-size-regular: 16px;
$font-size-large: 18px;
$font-size-x-large: 24px;
$font-size-xx-large: 32px;
$heading-md: 46px;
$heading-lg: 64px;
$font-head: 95px;



/*font-family*/

$font-fall :Arial, Helvetica, sans-serif;
$font-300: 'Archivo Regular', $font-fall;
$font-400: 'Archivo Regular', $font-fall;
$font-500: 'Archivo Medium', $font-fall;
$font-600: 'Archivo SemiBold', $font-fall;
$font-700: 'Archivo Bold', $font-fall;
/*font-family*/
