.profile-sidebar {
    position: fixed;
    top: 0;
    left: calc((100% - 1140px) / 2);
    width: 350px;
    height: auto;
    min-height: calc(100vh - 70px);
    z-index: 1;
    padding: 0 0 60px 0;
    margin-top: 70px;
    @media(max-width:1100px){
        width: 336px;
        left:  15px;
    }
    @media(max-width:992px){
        width: 0px;
        z-index: 3;
        .back-link {
            margin: 0 0 10px 0;
            padding-top: 10px;
            padding-left: 20px;
            background: #fafaf2;
            width: 100vw;
            .back-link {
                width: auto;
            }
            h1 {
                font-size: 1.5rem;
            }
            a {
                padding: 4px 4px;
            }
        }
        .edit-nav {
            max-height: 100%;
            min-height: auto;
            height: 100%;
            overflow: unset;
            top: 70px;
            margin-top: 0;
            .nav-toggal {
                top: 44px;
            }
            &.open {
                .rotate-icon {
                    transform: rotate(180deg)
                }
            }
        }
    }
    .appointment-heading {
        @include font-mixin($font-600, 18px, #3b3b3b, normal);
        margin: 30px 0;
    }
}
