
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  font-size: 10px !important;
  min-width: auto !important;
}
body {
  height: 100%;
  @include font-mixin($font-400, 1.6rem, $theme-black-color, normal, 23px);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0;
  background: #fafaf2;
  padding: 0;
  margin: 0;
  min-width: auto !important;
  cursor: default;
  overflow: auto !important;
}
.App{
  @include font-mixin($font-400, 1.6rem, $theme-black-color, normal, 23px);
}
.a-link{
  cursor: pointer;
  @include font-mixin($font-600, 1.4rem, $theme-dark-blue, normal, 22px);
  &:hover{
    text-decoration:none;
    color: $theme-orange-color;
  }
}
a,
a:hover,
a:active,
a:visited,
a img,
img {
  outline: none !important;
}
.clearfix {
  @include clearfix;
}

.position-relative {
  position: relative;
}

.display-text{
  @include font-mixin($font-700, 6.8rem, $theme-black-color, normal, 102px, 0.15);
}
.title-text{
  @include font-mixin($font-600, 3.4rem, $theme-black-color, normal, 51px, -0.35px);
}
.header-text{
  @include font-mixin($font-600, 2rem, $theme-black-color, normal, 30px, 0.15);
}
.subheader-text{
  @include font-mixin($font-600, 1.6rem, $theme-black-color, normal, 26px, 0.12);
}
.bodylight-text{
  @include font-mixin($font-400, 1.6rem, $theme-black-color, normal, 24px, 0.15);
}
.link-text{
  @include font-mixin($font-400, 1.4rem, $theme-black-color, normal, 14px, 0.8);
}
.theme-color{
  color:$theme-orange-color;
}
.container{
  max-width: 1170px;
  @media(max-width:1200px){
    max-width:100% !important;
  }
}
.caption-semibold-text{
  @include font-mixin($font-600, 1.4rem, $theme-black-color, normal, 22px, 0.2);
}
.caption-light-text{
  @include font-mixin($font-400, 1.4rem, $theme-black-color, normal, 22px, 0);
}
.caption-small-text{
  @include font-mixin($font-600, 1.2rem, $theme-black-color, normal, 18px, 0.4);
}
.dark-gray{
  color: $dark-grey;
}
.dark-gray-2{
  color: $dark-grey-2;
}
.word-break{
  word-break:break-word;
}
.cursor-pinter {
  cursor: pointer;
}
.p-24{
  padding: 24px;
}
.p-16{
  position: relative;
  min-height: 1px;
  padding-right: 16px;
  padding-left: 16px;
}
.m--16{
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
}
.p-15{
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.m--15{
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.p-5{
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}
.m--5{
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.login-title{
  text-align: center;
  margin-bottom: 30px;
  h1{
    @include font-mixin($font-600, 1.8rem, #4a4a4a, normal, 24px);
    margin-bottom: 0 !important;
  }
  h2{
    margin-bottom: 0 !important;
    @include font-mixin($font-400, 1.3rem, #787067, normal, 23px);
    margin-top: 10px !important;
    }
  }
  .forgot{
    a{
      @include font-mixin($font-400, 1.4rem, #787067 !important, normal);
      &:hover{
        text-decoration:underline !important;
      }
    }
  }
  .emal-sent{
    text-align: center;
    .check-box{
      width: 100px;
      height: 100px;
      background-color:#77D9D3;
      display: flex;
      align-items: center;
      justify-content:center;
      margin: 0 auto;
      border-radius: 50%;
      span{
        font-size:50px;
        color: #fff;
      }
    }
    h1{
      @include font-mixin($font-600, 1.8rem, #4a4a4a, normal, 23px);
      margin-bottom: 0 !important;
    }
    h2{
      @include font-mixin($font-400, 1.3rem, #787067, normal, 23px);
      margin-top: 10px !important;
      span{
        font-family: $font-500;
      }
    }
    &.thanks {
      h2{
        a{
          color:#FB7304;
        }
      }

    }
  }
  .login-footer{
    height:60px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    background-color:#fff;
    border-top: 1px solid #f0f0f1;
    position: fixed;
    bottom:0;
    left:0;
    width: 100%;
    z-index: 99;
    @media (max-width:992px){
      height: auto;
      padding: 15px 0;
      position: inherit;
    }
    p{
      @include font-mixin($font-400, 1.4rem, #787067, normal);
      margin: 0;
    }
    ul{
      padding:0;
      margin: 0;
      list-style: none;
      display:flex;
      align-items:center;
      li {
        margin-left: 60px;
        a{
          @include font-mixin($font-400, 1.4rem, #787067 !important, normal);
          &:hover{text-decoration:underline;}
        }
        &.active {
          a {
            color: #FB7304 !important;
          }
        }
      }
    }
    @media(max-width:992px){
      .d-flex{
        flex-direction: column;
        text-align:center;

      }
      ul{
        align-items:center;
        justify-content:center;
        flex-wrap: wrap;
        li{
          margin: 5px 15px 0;
        }
      }
      li{
        margin-left: 0;
      }
    }
    @media(max-width:640px){
      p {
        font-size: 13px;
      }
      ul{
        li{
          margin: 5px 6px 0;
          a {
            font-size: 13px;
          }
        }
      }
    }
  }
  .signup-text {
    text-align:center;
    margin-top: 10px;
    @include font-mixin($font-400, 1.4rem, #787067, normal);
    a{
      color: $theme-orange-color !important;
      text-decoration: underline;
      &:hover{
        text-decoration:underline !important;
      }
    }
  }
.head-title{
  margin-top: 26px;
  text-align: center;
  .heading{
    @include font-mixin($font-400, 1.6rem, #4a4a4a, normal, 23px);
  }
  .sub-heading{
    @include font-mixin($font-500, 1.6rem, $theme-orange-color, normal, 23px);
    margin-top: 15px;
  }
}
.form-skip{
  span{
    @include font-mixin($font-500, 1.4rem, $theme-orange-color, normal, normal);
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
  }
}
.step-logo {
  padding-top: 25px;
  img{
    width:200px ;
  }
}

.step-page{
  h3{
    @include font-mixin($font-500, 1.6rem, #4a4a4a, normal);
    text-align: left;
    margin-bottom: 20px;
  }
  header{
    background-color: $theme-green-color;
    min-height: 40px;
    display:flex;
    align-items: center;
    justify-content: center;
    position:inherit !important;
    p{
      margin: 0;
      @include font-mixin($font-400, 1.4rem, #30514e, normal);
      .alink{
        @include font-mixin($font-500, 1.4rem, #30514e, normal);
        text-decoration: underline;
      }
    }
  }

  footer{
    min-height:64px;
    background:#fff;
    margin-top: 0;
    border-top: 1px solid #f0f0f1;
    padding: 10px 0;
    .footer-btn{
      display:flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @media (max-width:767px){
        .btn{
          min-width: 130px !important;
        }
      }
      .why-we-ask{
        position: absolute;
        bottom: 54px;
        left: 0;
        text-align: center;
        width: 250px;
        z-index: -1;
        span{
          @include font-mixin($font-500, 1.4rem, #fff, normal);
          border-radius: 15px 15px 0 0;
          background-color: #77D9D3;
          display: inline-block;
          padding: 18px 15px 17px;
          min-height: 50px;
          width: 200px;
          margin-bottom: 0 auto;
          cursor: pointer;
        }
        .inner-div{
          border-radius: 5px 5px 0 0;
          padding: 30px;
          background-color: #77D9D3;
          transition: all 0.2s ease 0s;
          &.active{
            height: 0;
            padding: 0 30px;
          }
          h5{
            @include font-mixin($font-500, 1.4rem, #fff, normal);
            margin: 0;
          }
          p{
            @include font-mixin($font-400, 1.4rem, #fff, normal,24px);
            margin-top: 8px;
            margin-bottom: 0;
            padding: 0;
          }
        }
        @media (max-width:767px){
          width: 100%;
          span{
            width: 100% !important;
          }
          .inner-div{
            border-radius: 0;
          }
        }
      }
    }
    &.static-footer {
      position: relative;
      background: transparent;
      border: 0;
      .footer-btn {
        justify-content: flex-end;
        .btn {
          min-width: 120px;
          &.btn-primary {
            margin-left: 15px;
          }
        }
      }
    }
  }
  .middle{
    background:#fafaf2;
    min-height: calc(100vh - 100px);
    padding-bottom: 100px;
    @media (max-width:767px){
      min-height: calc(100vh - 150px);
      padding-bottom: 150px;
    }
    .stepper{
      margin-top: 50px;
      margin-bottom: 50px;
      max-width: 544px;
      margin-left: auto;
      margin-right: auto;
      .content{
        display: flex;
        justify-content: space-between;
        align-items:center;
        .box{
          position: relative;
          width: 100%;
          z-index: 1;
          .number{
            width: 30px;
            height: 30px;
            background:#dddcdc;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            @include font-mixin($font-500, 1.6rem, rgba(74,74,74, 0.7), normal);
            @media (max-width:767px){
              font-size:1.4rem;
              width: 24px;
              height: 24px;
            }
          }
          .material-icons{
            width: 24px;
            height: 24px;
            background:$theme-orange-color;
            color: $white;
            display: none;
            border-radius: 50%;
            font-size:20px;
            align-items: center;
            justify-content: center;
            @media (max-width:767px){
              font-size:1.6rem;
              width: 20px;
              height: 20px;
            }
          }
          &.active{
            .step-content{
              width: 30px;
              height: 30px;
              border: 1px solid $theme-orange-color;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background:$white;
              @media (max-width:767px){
                width: 24px;
                height: 24px;
              }
            }
            .number{
              background:$theme-orange-color;
              color: #fff;
              border: 0px solid #fff;
              width: 24px;
              height: 24px;
              @media (max-width:767px){
                width: 20px;
                height: 20px;
              }
            }
            &:before{
              top:14px;
              @media (max-width:767px){
                top: 11px;
              }
            }
          }
          &:before{
            position:absolute;
            left:0;
            top:14px;
            content: "";
            height: 2px;
            width: 100%;
            z-index: -1;
            background:#d5d4d4;
            @media (max-width:767px){
              top: 11px;
            }
          }

          &.check{
            .number{
              display: none;
            }
            .material-icons{
              display: flex;
              width: 30px;
              height: 30px;
              @media (max-width:767px){
                width: 24px;
                height: 24px;
              }
            }
            &:before{
              position:absolute;
              z-index: -1;
              left:0;
              top:14px;
              content: "";
              height: 2px;
              background:$theme-orange-color;
              @media (max-width:767px){
                top: 11px;
              }
            }
          }
          &:last-child{
            width: auto;
            &:before{
              display:none;
            }
          }
        }
      }
    }

  }
}
.form-step{
  max-width: 550px;
  margin: 0 auto;
  h3{
    @include font-mixin($font-500, 1.6rem, #4a4a4a, normal, 24px);
    text-align: left;
    margin-bottom: 20px;
  }
  form{
    textarea{
      height: 150px;
      resize: none;
    }
    .form-box{
      width: 100%;
      border: 1px solid #f0f0f1;
      border-radius: 8px;
      min-height: 64px;
      padding: 12px 20px;
      background:#fff;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 15px;
      span{
        width: 100%;
        display: block;
        margin-bottom: 8px;
        font-size:12px;
        color: #777;
        line-height:normal;

      }
      .text-value{
        @include font-mixin($font-500, 1.4rem, #3B3B3B, normal, 20px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .form-check{
      min-height: 100%;
      display: flex;
    }


    .label--radio, .label--checkbox {
      width: 100%;
      border: 1px solid #fff;
      border-radius: 8px;
      min-height: 66.8px;
      padding: 20px;
      background:#fff;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &.flat{
        background: transparent;
        box-shadow: none;
        border: 0;
        padding: 0;
        min-height: auto;
        display: inline;
      }
      label{
        @include font-mixin($font-400, 1.4rem, #3B3B3B, normal, 18px);
        margin-left: 15px;
        margin-bottom: 0;
        z-index: 0;
        pointer-events: none;
      }
      &.active{
        border-color: $theme-orange-color;
        background:rgba(230, 117, 43, 0.05) ;
      }
    }
    .multi-radio{
      width: 100%;
      border: 1px solid #fff;
      border-radius: 8px;
      min-height: 64px;
      padding: 12px 15px;
      background:#fff;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
      span{
        width: 100%;
        display: block;
        margin-bottom: 8px;
        font-size:12px;
        font-family: $font-400;
        color: #777;
        line-height:20px;
      }
      .label--radio, .label--checkbox {
        background:none;
        border:0;
        margin-bottom: 0;
        padding: 0;
        width: auto;
        min-height:auto;
        border-radius:0;
        box-shadow:none;
        margin-right: 24px;
        label{
          color: #666;
          font-family: $font-500;
        }
      }
    }
  }
  &.summary{
    .btn-group{
      margin-top: 65px;
      width: 100%;
      button{
        margin: 0 auto;
        max-width:350px;
      }
    }
    h4{
      @include font-mixin($font-500, 1.6rem, #4a4a4a, normal, normal);
      margin-bottom: 30px;
    }
    .detail{
      padding: 25px 0 15px;
      border-bottom: 1px solid #e3e2da;
      .number-heading{
        @include font-mixin($font-500, 1.4rem, #4a4a4a, normal, 18px);
        margin-bottom: 10px;
        margin-right: 50px;
      }
      &:last-child{
        border-bottom:0;
      }

      .material-icons{
        color: #4a4a4a;
        margin-top: 5px;
        cursor: pointer;
      }

      .content{
        display: flex;
        justify-content:space-between;
        align-items:flex-start;
        > a{
          padding: 0 !important;
          margin-top: -15px;
          span {
            padding: 8px;
          }
        }
        p{
          @include font-mixin($font-400, 1.3rem, #666, normal, 23px);
          margin: 0;
        }
        .value-list{
          margin: 0;
          padding: 0;
          list-style: none;
          display:flex;
          flex-wrap: wrap;
          li{
            @include font-mixin($font-400, 1.3rem, #666, normal, 23px);
            padding-right: 40px;
            position: relative;
            margin-bottom: 10px;
            &:after {
              content: "|";
              position: relative;
              right:-20px;
              top:0;
              @include font-mixin($font-400, 1.3rem, #666, normal, 23px);
            }
            &:last-child{
              padding-right: 0;
              &:after{
                display: none;
              }
            }
          }
        }
        .chip-value-list{
          margin: 0;
          padding: 0;
          list-style: none;
          display:flex;
          flex-wrap: wrap;
          li{
            @include font-mixin($font-400, 1.4rem, #392112, normal, normal);
            background:rgba(230,117,43, 0.1);
            border-radius: 15px;
            padding: 7px 20px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .no-data-section {
    background: transparent;
    box-shadow: none;
    width: 100%;
  }
}
.check-btn{
  margin-top: 24px;
  margin-bottom: 23px;
  @media(max-width:767px){
    margin-left: 15px;
  }

  label{
    color: #4a4a4a !important;
    line-height: 22px !important;
  }
}
.back-login{
  position: relative;
  .back-link{
    margin: 0;
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 5;
  }
}
.reg-screen{
  text-align: center;
  padding-top:45px;
  position: relative;

  .login-title{
    margin-top: 45px;
  }
  .box-content{
    display: flex;
    justify-content: center;
    @media(max-width:767px){
      flex-direction:column;
      align-items:center;
    }
    .box{
      max-width:250px;
      width: 100%;
      min-height:250px;
      background:#fff;
      border-radius: 8px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
      padding: 15px;
      margin: 0 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      .icons{
        height:75px;
        width:75px;
        background-position:center center;
        background-repeat:no-repeat;
        margin:0 auto;
        &.patient{
          background-image:url('../images/patient-icon.svg');
        }
        &.doctor{
          background-image:url('../images/doctor-icon.svg');
        }
        &.hospital{
          background-image:url('../images/hospital-icon.svg');
        }
      }
      &:hover{
        text-decoration:none;
        border:1px solid $theme-orange-color;
        background:rgba(230, 117, 43, 0.05);
        .icons {
          &.patient{
          background-image:url('../images/patient-icon-orange.svg');
          }
          &.doctor{
            background-image:url('../images/doctor-icon-orange.svg') !important;
          }
          &.hospital{
            background-image:url('../images/hospital-icon-orange.svg');
          }
        }
        span{
          color: $theme-orange-color;
        }
        h4{
          color: #392112;
        }
      }
      @media(max-width:767px){
        margin-bottom:20px;
        max-width: 200px;
        min-height: 150px;
        h4{
          margin-top:30px;
        }
      }

      span{
        font-size: 62px;
        color: #999;
      }
      h4{
        @include font-mixin($font-600, 1.4rem, #4a4a4a, normal, normal);
        margin-top: 50px;
      }
      &.active{
        border:1px solid $theme-orange-color;
        background:rgba(230, 117, 43, 0.05);
        span{
          color: $theme-orange-color;
        }
        h4{
          color: #392112;
        }

      }
    }
  }
}
.spinner-component{
  text-align: center;
  &.full-page-spinner{
    text-align: center;
    position: fixed;
    top: 0;
    background: rgba(255, 255, 255, .8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    z-index: 9999;
    .spinner-border{
      border: .25em solid currentColor;
      background: inherit;
      background-size: inherit;
      border-right-color: transparent;
      color: $theme-orange-color;
    }
    &.position-absolute{
      position: absolute;
      .spinner-border {
        width: 4.0rem;
        height: 4.0rem;
      }
    }
  }
  .spinner-border{
    border: 0;
    background:url('../images/small-icons-loader-icon.svg') left top no-repeat;
    background-size: cover;
  }
}

/* Rules for sizing the icon. */
.material-icons-outlined.md-20 { font-size: 20px; }
.material-icons-outlined.md-18 { font-size: 18px; }
.material-icons-outlined.md-24 { font-size: 24px; }
.material-icons-outlined.md-36 { font-size: 36px; }
.material-icons-outlined.md-48 { font-size: 48px; }

/* Rules for using icons-outlined as black on a light background. */
.material-icons-outlined.md-dark { color: #777 }
.material-icons-outlined.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons-outlined as white on a dark background. */
.material-icons-outlined.md-light { color: rgba(255, 255, 255, 1); }
.material-icons-outlined.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

/* Rules for sizing the icon. */
.material-icons.md-20 { font-size: 20px; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: #777 }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.Toastify__toast-container {
  width:auto !important;
  min-width: 320px;
  max-width:370px;
  @media (max-width:767px){
    width: auto !important;
  }
  .Toastify__toast {
    min-height: 55px;
    padding-left: 40px;
    padding-right: 15px;
    position: relative;
    &.Toastify__toast--success{
      background:#fff !important;
      border-radius: 8px;
      font-size: 14px;
      color: #3b3b3b;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05)!important;
      background-color: #ffffff;
      line-height:20px;
      font-family: $font-400;
      text-align: left;
      &::before {
        background: url('./../images/toast-success-icon.svg') no-repeat center;
        background-size: 24px;
      }
    }
    &.Toastify__toast--error{
      background:#fff !important;
      border-radius: 8px;
      font-size: 14px;
      color: #3b3b3b;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05)!important;
      background-color: #ffffff;
      line-height:20px;
      font-family: $font-400;
      text-align: left;
      &::before {
        background: url('./../images/toast-error-icon.png') no-repeat center;
        background-size: 20px;
      }
    }
    &.Toastify__toast--info{
      background:#fff !important;
      border-radius: 8px;
      font-size: 14px;
      color: #3b3b3b;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05)!important;
      background-color: #ffffff;
      line-height:20px;
      font-family: $font-400;
      text-align: left;
    }
    .Toastify__close-button {
      color:#333;
      margin-top: 10px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 15px;
      left: 14px;
      width: 24px;
      height: 24px;
    }
  }
}
.tost-container{
  position:absolute;
  left:0;
  top:100px;
  width: 100%;
  max-width:370px;
  .toast{
    border: 0;
    border-radius: 5px;
    padding: 15px;
    .toast-body{
      font-size:14px;
      font-family: $font-400;
      line-height:20px;
      color: $white;
    }
  }
}
.btn-white-outline{
  padding: 10px 15px;
  border-radius: 8px;
  border: solid 1px rgba(219, 219, 219, 0.8);
  @include font-mixin($font-600, 1.2rem, #777, normal, 18px);
  background:transition;
  transition: all .3s ease 0s;
  &:focus{
    text-decoration: none;
  }
  &:hover, &.active{
    background:$theme-orange-color;
    color: #fff;
    border-color: $theme-orange-color;
    text-decoration: none;
    span{
      color: #fff !important;
    }
  }
  &.disabled-icon, &:disabled {
    background: #e3e3e3;
    opacity: 0.6;
    cursor: default;
    span {
      color: #747474;
    }
    &:hover {
      border-color: transparent;
      span {
        color: #747474 !important;
      }
    }
  }
}
.btn-white-fav{
  padding: 10px 15px;
  border-radius: 8px;
  border: solid 1px rgba(219, 219, 219, 0.8);
  @include font-mixin($font-600, 1.2rem, #777, normal, 18px);
  background:transition;
  transition: all .1s ease 0s;
  &.active{
    background:$theme-orange-color;
    color: #fff;
    border-color: $theme-orange-color;
    text-decoration: none;
    span{
      color: #fff !important;
    }
  }
  &:hover{
    border-color: $theme-orange-color;
    text-decoration: none;
  }
}
.query-box {
  width: 100%;
  margin: 15px 0;
  .col-sm-12 {
    padding: 0;
  }
}
.faq-accordion {
  .card-body {
    .form-check {
      .multi-radio {
        .label--radio {
          label {
            pointer-events: none;
          }
        }
      }
    }
  }
}
.reverse{
  -moz-transform: rotate(180deg);
-webkit-transform: rotate(180deg);
-o-transform: rotate(180deg);
-ms-transform: rotate(180deg);
transform: rotate(180deg);
}

.csv-form{
  position: relative;
  height: calc(100vh - 130px);
  overflow: auto;
  background: #fafaf2;
  padding-bottom: 32px;
  @media (max-width: 992px){
    height: auto;
    margin-top: 70px;
  }
  .close-icon{
    float: right;
  }
  .text-decoration-underline{
    &:hover{
      color: $theme-orange-color;
    }
  }
  .add-provider-logo{
    width:100%;
    padding-right: 55px;
  }
  .csv-uploader{
    background: #FFFFFF;
    min-height: 187px;
    max-width:350px;
    margin: 0 auto;
    padding: 28px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    .upload-csv-wrraper {
      .btn {
        cursor:pointer;
        position:relative;
        z-index: 1;
        padding: 0 15px;
        line-height: 44px;
      }
      input{
        display:none;
      }
    }
    .material-icons-sharp {
      font-size: 21px;
      color: rgb(113, 113, 113);
      &.theme-color {
        color: #FB7304;
        font-size: 24px;
      }
    }
    .hint-text {
      margin-top: 5px;
    }
  }
  .csv-uploader-err{
    background: #fce8e8;
    max-width: 600px;
    border-width: 1px;
    border-style: solid;
    font-size: 1.2rem;
    color: #e94848;
    margin: 0 auto;
    padding: 20px 40px;
    border-radius: 12px;
    border-color: #e94848;
  }
  &.add-doctor-form{
    .middle{
      padding:0;
    }

  }
}
.thankyou-scheduling{
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  .user-info .head .title-text h2{
    margin-top: 0 !important;
    margin-bottom: 0;
  }
  .user-info .head .title-text{
    flex:initial;
    margin-top: 0;
  }
  .purchase-card{
    margin-bottom: 20px;
  }
  p{
    @include font-mixin($font-500, 1.4rem, #666, normal, 22px);
  }
  .text-right{
    width: 270px;
    @media (max-width: 767px){
      text-align:left !important;
      width: 100%;
      margin-top: 15px;
    }
  }
}
.check-icon{
  color: #fd7e14
}

.appointment-calendar{
  max-width:920px;
  width: 100%;
  margin: 0 auto;

}
.calander-row{
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  display:flex;
  overflow: hidden;
  .no-data-section{
    background: transparent;
    box-shadow: none;
  }
  .rbc-calendar {
    .rbc-row {
      .rbc-event {
        margin-top: 0 !important;
        top: -100% !important;
      }
    }
  }
  @media (max-width: 1024px){
    flex-direction: column;
    .rbc-calendar {
      height: auto !important;
      .rbc-row {
        .rbc-event {
          margin-top: 0 !important;
        }
        .rbc-row-segment {
          height: 51px;
        }
      }
    }
    .rbc-off-range-bg {
      background: rgba(0,0,0,0.04);
    }
    .rbc-month-row {
      min-height: 51px;
    }
    .rbc-day-bg {
      min-height: 50px;
    }
    .rbc-date-cell {
      min-height: 51px;
      &.rbc-now {
        &.rbc-current {
          background: #fb7302;
        }
      }
    }
  }
  .rbc-day-bg {
    cursor: pointer;
    z-index:11;
    &.date-in-past {
        border-right: 0;
        cursor: default;
        z-index: 1;
        & + .rbc-day-bg {
            z-index: 1;
        }
    }
    @media (max-width: 767px){
      &.rbc-today {
        &::after {
          display: none;
        }
      }
    }
  }
}
.date-sec{
  width: 68%;
  .rbc-calendar{
    border-radius: 0;
    width: 100%;
    .rbc-event {
      margin-top: -59px !important;
    }
    .rbc-day-bg.rbc-today {
      &:after {
        top: 8px;
      }
    }
  }
  > P{
    display: none;
  }
  @media (max-width: 767px){
    width: 100%;
    .rbc-calendar{
      padding:30px;
    }
  }
}
.time-sec{
  width: 32%;
  background-color: #f9f9f9;
  padding:30px 30px 40px;
  flex:1;
  position: relative;
  @media (max-width: 767px){
    width: 100%;
    padding:30px;
  }
  .head{
    display:flex;
    align-items:center;
    justify-content:space-between;
    h3{
      @include font-mixin($font-500, 1.6rem, #090706, normal, 18px);
    }
  }
  .sub-head{
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom: 25px;
    h5{
      @include font-mixin($font-500, 1rem, #80807f, normal, 10px);
    }
  }
  ul{
    list-style: none;
    margin: 0;
    max-height: 320px;
    overflow-y: auto;
    padding-right: 30px;
    li{
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
      .time-slot{
        padding: 15px 15px;
        width: 100%;
        border-radius: 8px;
        border: solid 1px #cdcdcd;
        background-color: #fff;
        min-height: 45px;
        text-align: center;
        @include font-mixin($font-500, 1.4rem, #666, normal, 18px);
        &:hover{
          cursor: pointer;
          color: $theme-orange-color;
          border-color: $theme-orange-color;
        }
      }
      &.active{
        .time-slot{
          color: #fff;
          background-color: $theme-orange-color;
          border-color: $theme-orange-color;
        }
      }
      &.disabled{
        opacity: 0.5;
        &:hover{
          .time-slot{
            cursor: auto;
            color: #666 !important;
            border-color:#cdcdcd !important;
          }
        }
      }
    }
  }

}
.zoomAppContainer {
  width: 100%;
  padding-left: 635px;
}
.meeting-note-container {
  width: 100%;
  height: 500px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  background: rgb(189, 189, 189);
}
