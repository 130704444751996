.select-schedule-slot {
    display: flex;
    margin-bottom: 50px;
    @media (max-width: 992px) {
        flex-wrap: wrap;
    }
}
.schedule-an-appointment {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    h4 {
        margin: 0;
        @include font-mixin($font-400, 15px, #777, normal);
    }
    .dropdown {
        .dropdown-toggle {
            width: 40px;
            display: flex;
            align-items: center;
            height: 40px;
            border-radius: 5px;
            background: rgba(#fb7304, 0.11);
                @include font-mixin($font-500, 12px, #f06f06, normal);
            padding: 0;
            border: 1px solid #fb7304;
            justify-content: center;
            &:after {
                display: none;
            }
            span {
                font-size: 18px;
            }
        }
        .dropdown-menu {
            border: 0;
            padding: 0;
            transform: none !important;
            top: 100% !important;
            right: 0 !important;
            left: auto !important;
            .rbc-day-bg {
                cursor: pointer;
                z-index:11;
                &.date-in-past {
                    border-right: 0;
                    cursor: default;
                    z-index: 1;
                    & + .rbc-day-bg {
                        z-index: 1;
                    }
                }
            }
            .rbc-date-cell {
                &.rbc-now {
                    &.rbc-current {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.filter-wrapper {
    width: 350px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    background: #fff;
    padding: 30px;
    h3 {
        @include font-mixin($font-600, 16px, #090706, normal);
        margin: 0 0 10px 0;
    }
    .setting-items {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;
        margin-bottom: 10px;
        border-bottom: 1px solid #d8d8d8;
        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        h4 {
            width: 100%;
            @include font-mixin($font-400, 14px, #777, normal);
            position: relative;
            cursor: pointer;
            margin-bottom: 20px;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                width: 20px;
                height: 20px;
                background: url('./../images/arrow-down.svg') no-repeat center;
                transition: all .4s ease-in-out;
                background-size: 20px;
                opacity: 0.4;
            }
            &.collapse-disabled {
                &:after {
                    display: none;
                }
            }
        }
        &.open {
            h4 {
                &:after {
                    transform: rotate(-180deg);
                }
            }
        }
        .collapse, .collapsing {
            padding-left: 5px;
        }
        .label--checkbox {
            background: none;
            box-shadow: none;
            margin-bottom: 7px;
            label {
                @include font-mixin($font-400, 14px, normal);
                color: #777  !important;
            }
            input:disabled {
                & + label {
                    opacity: 0.7;
                }
            }
        }
        .label--radio {
            background: none;
            box-shadow: none;
            margin-bottom: 7px;
            label {
                @include font-mixin($font-400, 14px, normal);
                color: #777  !important;
                display: inline-flex;
                span {
                    margin: 0 0 0 5px;
                    font-style: italic;
                }
            }
        }
        .select-timezone {
            width: 100%;
            .form-group {
                margin: 0;
                .react-select__control {
                    margin-bottom: 0;
                    background: #f4f4f4;
                }
                .label-float {
                    box-shadow: none !important;
                }
                .react-select__menu {
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
                }
            }
        }
        .custom-days-checkbox {
            padding-left: 40px;
        }
    }
    @media (max-width: 992px) {
        width: 100%;
    }
}
.timeslot-wrapper {
    width: calc(100% - 380px);
    margin-left: 30px;
    height: auto;
    .timeslot-container {
        width: 100%;
        border-radius: 8px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
        background: #fff;
        padding: 30px;
    }
    h3 {
        @include font-mixin($font-600, 16px, #090706, normal);
        margin: 0 0 45px 0;
    }
    .week-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        .week-slot {
            width: calc((100% / 7) - 5px);
            height: 34px;
            line-height: 34px;
            padding: 0 10px;
            background: #f4f4f4;
            text-align: center;
            @include font-mixin($font-500, 12px, #666, normal);
            &.active {
                background: rgba(#fb7304, 0.17);
                @include font-mixin($font-500, 12px, #d66000, normal);
            }
        }
        &.every-day {
            .week-slot {
                background: rgba(#fb7304, 0.17);
                @include font-mixin($font-500, 12px, #d66000, normal);
            }
        }
        &.week-day {
            .week-slot {
                background: rgba(#fb7304, 0.17);
                @include font-mixin($font-500, 12px, #d66000, normal);
                &:nth-child(6), &:nth-child(7) {
                    background: #f4f4f4;
                    @include font-mixin($font-500, 12px, #666, normal);
                }
            }
        }
    }
    .time-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .time-slot {
            width: calc((100% / 7) - 5px);
            margin-bottom: 15px;
            label {
                height: 45px;
                line-height: 45px;
                width: 100%;
                text-align: center;
                padding: 0 10px;
                border-radius: 8px;
                border: solid 1px rgb(177, 177, 177);
                background: #fff;
                @include font-mixin($font-500, 14px, rgb(177, 177, 177), normal);
                text-transform: uppercase;
                cursor: pointer;
            }
            input {
                display: none;
            }
            &:nth-child(4n) {
                margin-right: 0;
            }
            &.checked {
                label {
                    border-color: #fb7304;
                    background: rgba(251, 115, 4, 0.05);
                    @include font-mixin($font-500, 14px, #d66000, normal);
                }
            }
            &.booked {
                label {
                    border-color:#fb7304;
                    background: #fb7304;
                    @include font-mixin($font-500, 14px, #fff, normal);
                    cursor: default;
                }
            }
            &.pending {
                label {
                    border-color:#77d9d3;
                    background: rgba(#77d9d3, 0.1);
                    @include font-mixin($font-500, 14px, #77d9d3, normal);
                    cursor: default;
                }
            }
        }
    }
    .timeslot-button-wrapper {
        display: flex;
        justify-content: flex-end;
    }
    @media (max-width: 992px) {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
    }
    @media (max-width: 640px) {
        .timeslot-container {
            padding: 15px;
        }
        .time-row {
            justify-content: space-between;
            .time-slot {
                width: calc((100% / 3) - 5px);
                margin: 0 0 5px 0;
                label {
                    height: 35px;
                    line-height: 35px;
                    font-size: 12px;
                }
                &.checked {
                    label {
                        font-size: 12px;
                    }
                }
            }
        }
        .week-row {
            white-space: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            flex-wrap: nowrap;
            min-width: 100%;
            .week-slot {
                width: auto;
                font-size: 11px !important;
                margin: 0 3px;
            }
        }
        .rbc-calendar {
            width: 300px;
            height: auto !important;
            .rbc-row {
                .rbc-event {
                    margin-top: 0 !important;
                }
                .rbc-row-segment {
                    height: 51px;
                }
            }
            .rbc-off-range-bg {
                background: rgba(0,0,0,0.04);
            }
            .rbc-month-row {
                min-height: 51px;
            }
            .rbc-day-bg {
                min-height: 50px;
            }
            .rbc-date-cell {
                min-height: 51px;
                &.rbc-now {
                    &.rbc-current {
                        background: #fb7302;
                    }
                }
            }
            .rbc-today {
                &::after {
                  display: none;
                }
            }
        }
        .timeslot-header {
            flex-wrap: wrap;
            h3 {
                width: 100%;
            }
            .timeslot-header-items {
                flex: none;
                justify-content: space-between;
                width: 100%;
                .schedule-an-appointment {
                    padding: 0;
                    margin-top: 20px;
                }
            }
        }
    }
}
.suggest-different-time-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    h3 {
        width: 100%;
        text-align: left;
        @include font-mixin($font-600, 18px, #4a4a4a, normal);
        margin: 20px 0 30px 0;
    }
    .calendar-wrapper {
        width: calc(100% - 275px);
        .rbc-calendar {
            width: 100%;
            height: 415px !important;
            padding: 0;
            box-shadow: none;
            .date-in-past {
                border-right: 0;
              }
        }
    }
    .available-slots {
        width: 275px;
        padding-left: 30px;
        position: relative;
        min-height: 400px;
        h3 {
            @include font-mixin($font-500, 16px, #090706, normal);
            margin: 5px 0;
        }
        p {
            @include font-mixin($font-500, 14px, #777, normal);
            text-align: left;
            margin: 0;
            width: 100%;
            max-width: 100%;
        }
        .calendar-setting {
            text-align: left;
            span {
                display: block;
                width: 100%;
                @include font-mixin($font-500, 12px, #777, 18px);
            }
            a {
                @include font-mixin($font-500, 14px, #fb7304, normal);
            }
        }
    }
}
.suggested-time-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0 20px 0;
    justify-content: space-between;
    align-content: flex-start;
    height: 300px;
    overflow-y: auto;
    .time-slot {
        width: calc(50% - 7px);
        margin-bottom: 15px;
        label {
            height: 45px;
            line-height: 45px;
            width: 100%;
            text-align: center;
            padding: 0 10px;
            border-radius: 8px;
            border: solid 1px #cdcdcd;
            background: #fff;
            @include font-mixin($font-500, 14px, #666, normal);
            text-transform: uppercase;
            cursor: pointer;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                text-align: center;
            }
        }
        input {
            display: none;
            &:checked + label {
                border-color: #fb7304;
                background: rgba(251, 115, 4, 0.05);
                @include font-mixin($font-500, 14px, #d66000, normal);
            }
            &:disabled {
                & + label {
                    opacity: 0.5;
                    cursor: default;
                }
            }
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
    .no-available-slots {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
            text-align: center;
        }
    }
}
.suggest-different-time-btn-wrapper {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.timeslot-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
    h3 {
        display: inline-flex;
        margin-bottom: 0 !important;
        flex: none;
    }
    .timeslot-header-items {
        flex: 1 1;
    }
}
.set-session-fee-row {
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #d8d8d8;
    position: relative;
    p {
        @include font-mixin($font-500, 16px, #090706, normal);
        margin: 0;
    }
    .session-fee-input {
        width: 309px;
        margin-left: 20px;
        height: 45px;
        background:#f4f4f4;
        border-radius: 4px;
        display: flex;
        overflow: hidden;
        .currency-sign {
            height: 45px;
            line-height: 45px;
            width: 45px;
            text-align: center;
            position: relative;
            color:  #777;
            font-size: 15px;
            &:after {
                content: "";
                height: 20px;
                width: 1px;
                position: absolute;
                top: 12px;
                right: 0;
                background: #d8d8d8;
            }
        }
        input {
            width: calc(100% - 100px);
            height: 45px;
            border: 0;
            background: none;
            padding: 0 15px;
            color:#090706;
            font-size: 15px;
            transition: none !important;
            outline: 0 !important;
            box-shadow: none !important;
            &:focus, &:hover {
                border: 0 !important;
                outline: 0 !important;
                box-shadow: none !important;
                outline-offset: 0px !important;
            }
        }
        .currency-name {
            width: 60px;
            height: 45px;
            background: #d8d8d8;
            color: #777;
            text-align: center;
            line-height: 45px;
            font-size: 15px;
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #f4f4f4 inset !important;
    }
    .error {
        position: absolute;
        bottom: -26px;
        left: 144px;
        font-size: 13px;
        color: $red;
    }
    .btn {
        margin-left: 20px;
        min-width: 100px;
        height: 45px;
        &:hover, &:focus {
            outline: 0;
            border-color: transparent;
        }
    }
    @media (max-width: 640px) {
        flex-wrap: wrap;
        p {
            width: 100%;
            margin-bottom: 15px;
        }
        .session-fee-input {
            width: calc(100% - 100px);
            margin: 0;
        }
        .btn {
            min-width: 90px;
            margin: 0 0 0 10px;
        }
    }
}
