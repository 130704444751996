header {
  background: $theme-orange-color;
  min-height: 70px;
  @media(max-width:992px){
    min-height:70px;
    position: fixed;
    left:0;
    top:0;
    width: 100%;
    z-index: 15;
  }
  .headerSection{
    display: flex;
    align-items: center;
    justify-content:space-between;
    min-height: 70px;
    @media(max-width:992px){
      min-height:70px;
    }
    .header-search{
      flex: 1;
      .form-group{
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        input{
          padding: 10px;
          min-height: 42px;
          max-width: 350px;
          width: 100%;
          @include font-mixin($font-400, 1.4rem, $theme-black-color, normal);
          &::placeholder{
            font-style: italic;
          }
        }
      }
    }
    .small-right-nav{
      ul {
        list-style: none;
        margin-bottom: 0;
        display:flex;
        align-items: center;
        justify-content:flex-start;
        li{
          &.calendar-nav{
            a{
              &:hover{
                text-decoration:none;
              }
            }
          }
          .btn-group >
          button{
            min-width: auto;
            border: 0 !important;
            background: transparent !important;
            color: #fff !important;
            min-height: auto !important;
            margin: 0 !important;
            padding: 0;
            display: flex;
            align-items: center;
            &:hover{
              background: transparent !important;
            }
            &:focus{
              outline: none !important;
              border:0 !important;
              box-shadow: none !important;
            }
            .profile-img{
              height: 35px;
              width: 35px;
              border-radius: 50%;
              margin-right: 6px;
              img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
              }
            }
          }
          .dropdown-menu{
            top: 40px !important;
            left:50px !important;
          }
          a{
            @include font-mixin($font-400, 1.4rem, #fafaf2, normal);
            font-style: italic;
            margin-left: 10px;
            display:flex;
            >span{
              font-size: 21px;
            }
            &:hover{
              color: #fff;
            }
          }
        }
      }
    }
    nav {
      ul {
        li {
          a {
            @include font-mixin($font-600, 1.4rem, #fff, normal);
            &:not([href]) {
              color: #fae3d4;
            }
            &:hover, &:focus {
              text-decoration: none;
              &:not([href]) {
                color: #fff;
              }
            }

          }
          &:hover{
            a{
              color: #fff;
            }
          }
          &.active{
            a {
              color: #fff;
              border-color: #fff;
            }
          }
        }
      }
    }
  }
}
.btn{
  &:focus{
    box-shadow: none;
  }
}
.btn-primary{
  &:active{
    background:$theme-orange-color !important;
  }
}
.placeholder-text{
  @include font-mixin($font-600, 4.4rem, $grey, normal);
  margin: 100px;
  text-align: center;
}

.full-bg-color{
  background-color:#fafaf2;
  height:100vh;
  .landing-ui{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    text-align: center;
    .box{
      max-width: 500px;
    }
    h1{
      @include font-mixin($font-700, 1.8rem, #21140b, normal);
      margin-top: 24px;
      margin-bottom: 15px;
    }
    p{
      @include font-mixin($font-400, 1.4rem, #787067, normal);
      margin-bottom: 30px;
    }
  }
}
.icon-w{
  width: 24px;
}
/*nav*/
.nav{
  flex: 1;
  margin-left: 100px;
  ul{
    display:flex;
    align-items: center;
    justify-content: start;
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      margin-right: 60px;
      a{
        @include font-mixin($font-400, 1.4rem, #fae3d4, normal);
        border-top: 1px solid $theme-orange-color;
        padding-top: 4px;
        display: inline-block;
        transition: all .3s ease 0s;
        text-transform: uppercase;
        &.active{
          border-color: $white;
          color: #fff;
        }
        &:hover, &.active:hover{
          text-decoration:none;
          border-color: $white;
          color: #fff;
        }
      }
    }
  }
  @media(max-width:992px){
    margin-left: 0;
    position: fixed;
    left: 0;
    top: 70px;
    height: calc(100% - 70px);
    width: 100%;
    background: rgba(0,0,0,0.8);
    z-index: 10;
    display: none;
    ul{
      background:$theme-orange-color;
      flex-direction: column;
      align-items: self-start ;
      width:270px;
      padding: 15px;
      position: relative;
      left: -200px;
      transition: 0.3s all ease;
      li{
        margin: 26px 0;
        border-bottom: 1px solid rgba(216,216,216, 0.3);
        width: 100%;
        &:last-child{
          border: 0;
        }
        a{
          border-top: 1px solid #fff;
          @include font-mixin($font-600, 1.4rem, #fff, normal);
        }
      }
    }
    &.open{
      display: flex;
      ul{
        left: 0px;
      }
    }
  }
}
.profile-dropdown{
  .dropdown-menu{
    min-width:270px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    border:0;
    .dropdown-header{
      padding: 0;
      margin-bottom: 5px;
      @include font-mixin($font-500, 1.4rem, #4a4a4a, normal);
      font-style: initial;
    }
    .dropdown-item{
      padding: 15px 5px;
      border-bottom: 1px solid #d8d8d8;
      display:flex;
      @include font-mixin($font-400, 1.4rem, #777, normal, 22px);
      margin: 0;
      font-style: normal;
      span{
        margin-right: 15px;
      }
      &:last-child{
        border-bottom:0;
      }
      &:hover{
        background-color:transparent;
        color: #3b3b3b;
        span{
          color: $theme-orange-color;
        }
      }
    }
  }
}
/*nav*/

.header-logo{
  display:flex;
  align-items: center;
  a{
    @media(max-width:992px){
      img{
        max-width: 110px;
      }
      margin-top: -10px;
    }
  }
  .material-icons{
    display: none;
    margin-right: 15px;
    color:#fff;
    cursor: pointer;
    @media(max-width:992px){
      display: block;
    }
  }
}
.calendar-nav {
  a {
    margin-right: 10px;
    span {
      color: #fff;
    }
  }
}
